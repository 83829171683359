import React from 'react';

function shadeColor(color, percent) {
  if(color !== undefined)
  var R = parseInt(color.substring(1,3),16);
  var G = parseInt(color.substring(3,5),16);
  var B = parseInt(color.substring(5,7),16);
  R = parseInt(R * (100 + percent) / 100);
  G = parseInt(G * (100 + percent) / 100);
  B = parseInt(B * (100 + percent) / 100);
  R = (R<255)?R:255;
  G = (G<255)?G:255;
  B = (B<255)?B:255;
  var RR = ((R.toString(16).length===1)?"0"+R.toString(16):R.toString(16));
  var GG = ((G.toString(16).length===1)?"0"+G.toString(16):G.toString(16));
  var BB = ((B.toString(16).length===1)?"0"+B.toString(16):B.toString(16));
  return "#"+RR+GG+BB;
}

//.outline-textlogo{fill:#1A2036;}
//.shadow-primary-textlogo{fill:#303F8B;}
//.shadow-secondary-textlogo{fill:#3C4EAD;}
//.main-textlogo{fill:#4352AF;}

const Logo = (color) => (
  <svg
      height="50px"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 330 106.5">
  <style type="text/css">
    {
    `.st0 { display:none; }
    .st1{display:inline;fill:#1A2036;}
    .st2{fill:#FFFFFF;}
    .outline-textlogo{fill:${color.color === undefined ? shadeColor('#4352AF', -90) : shadeColor(color.color, -90)};}
    .shadow-primary-textlogo{fill:${color.color === undefined ? shadeColor('#4352AF', -30) : shadeColor(color.color, -30)};}
    .shadow-secondary-textlogo{fill:${color.color === undefined ? shadeColor('#4352AF', -60) : shadeColor(color.color, -60)};}
    .main-textlogo{fill:${color.color === undefined ? '#4352AF' : color.color};}
    .st7{fill:#4C5BC2;}
    .st8{fill:#C4CABC;}
    .st9{fill:#FCFCFD;}
    .st10{fill:#D27489;}
    .st11{fill:#B96678;}
    .st12{fill:#00AEAE;}`
  }
  </style>
  <g>
    <g>
      <g>
        <g className="st0">
          <rect x="-45.1" y="-44.2" className="st1" width="466.9" height="205.4"/>
        </g>
        <g>
          <g>
            <path className="st2" d="M324.8,61.7v6h-18.2v-5.1l8.3-6.9h-8.3v-6h18.2v5l-8.3,6.9H324.8z M311.2,76.8v9.1h-4.6V70.7h18.2v14.8
              h-4.6v-8.8h-2.1v7.7h-4.4v-7.7H311.2z M306.6,92.3h13.5V87h4.8v16.8h-4.8v-5.3h-13.5V92.3z"/>
            <path className="st2" d="M209,103.8l-3.6-9.4h-20.6l-3.6,9.4h-18.5l23.6-54.1h17.9l23.6,54.1H209L209,103.8z M189.8,81.2h10.5
              l-5.3-13.6L189.8,81.2L189.8,81.2z M250.9,105L250.9,105c-4.5,0-8.8-0.5-13.1-1.5l0,0l0,0c-4.3-1-7.7-2.4-10.5-4.1l0,0
              l5.9-13.3l0,0c2.6,1.5,5.5,2.7,8.7,3.6l0,0l0,0c3.2,0.9,6.3,1.4,9.2,1.4l0,0l0,0c2.5,0,4.3-0.2,5.4-0.7l0,0l0,0
              c1.1-0.5,1.6-1.2,1.6-2.2l0,0l0,0c0-1.1-0.7-2-2.1-2.6l0,0l0,0c-1.4-0.6-3.7-1.2-7-1.9l0,0l0,0c-4.2-0.9-7.6-1.8-10.4-2.8l0,0
              l0,0c-2.8-1-5.2-2.7-7.3-4.9l0,0l0,0c-2.1-2.3-3.1-5.4-3.1-9.3l0,0l0,0c0-3.4,1-6.5,2.9-9.3l0,0l0,0c1.9-2.8,4.8-5,8.5-6.6l0,0
              l0,0c3.8-1.6,8.4-2.4,13.8-2.4l0,0l0,0c3.7,0,7.4,0.4,10.9,1.2l0,0l0,0c3.6,0.8,6.7,2,9.5,3.5l0,0l-5.5,13.2l0,0
              c-5.3-2.7-10.3-4-15.1-4l0,0l0,0c-4.7,0-7,1.1-7,3.4l0,0l0,0c0,1.1,0.7,1.9,2.1,2.4l0,0l0,0c1.4,0.5,3.7,1.1,6.9,1.7l0,0l0,0
              c4.1,0.8,7.6,1.7,10.4,2.7l0,0l0,0c2.8,1,5.3,2.6,7.4,4.9l0,0l0,0c2.1,2.3,3.1,5.4,3.1,9.3l0,0l0,0c0,3.4-1,6.5-2.9,9.2l0,0
              l0,0c-1.9,2.8-4.8,4.9-8.5,6.6l0,0l0,0C260.9,104.2,256.3,105,250.9,105L250.9,105L250.9,105z M281.5,103.8V49.7h18.2v54.1
              H281.5L281.5,103.8z M45.5,103.8v-40H29.6V49.7h49.9v14.1H63.7v40H45.5L45.5,103.8z"/>
            <path className="st2" d="M153.9,48.1l17.3-1l-14.1,55l-19.5,1.1l-10.4-28.3l-7.5,29.4l-19.5,1.1l-20.4-53l18.7-1.1l11.2,30.2
              l8.2-31.3l16.7-1l11.2,30.5L153.9,48.1L153.9,48.1z"/>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path className="outline-textlogo" d="M18.7,76.1c-4-0.4-6.7-1.5-9.2-3.8c-3.3-2.9-4.7-6-5.5-12.1c-0.3-2.5-0.3-6.5,0-9.4
                c0.9-7.4,4-15.1,8.9-22.2c2.4-3.5,6.4-8.2,9.2-10.9c0.2-0.1,0.8-0.7,1.3-1.3c2.9-2.8,6.6-5.8,9.7-7.8c4-2.6,7.6-4.1,11.5-5
                c1.1-0.3,1.7-0.3,3.4-0.4C50.2,3,51.4,3,53,3.3c4,0.6,7.6,2.3,10.9,5c1,0.8,2,1.7,2.6,2.3L67,11l0-1.5c0-0.8,0-1.8,0.1-2.1
                c0.3-2,1.4-4.1,2.9-5.4c0.8-0.7,1.1-0.9,1.9-1.1c1.6-0.5,2.6-0.3,6.3,0.9c3.2,1,6.9,2.7,9.5,4.2c6.1,3.7,10.4,8.6,12.5,14.4
                c0.6,1.6,1,3.9,0.9,5.6c-0.1,1.2-0.3,2.9-0.7,4c-0.2,0.8-1.1,2.9-1.2,2.9c0,0-0.1,0.1-0.1,0.2c-0.1,0.3-1,1.6-1.5,2.2
                c-2.1,2.6-4.8,4.2-8.3,5c-1.2,0.3-4.4,0.4-5.6,0.3c-3.5-0.4-6.5-1.1-9.9-2.4c-0.6-0.2-1.1-0.4-1.1-0.4c0,0,0,0.9,0,1.9l0,1.8
                l0.8,0c1.8-0.1,3.3,0.1,4.1,0.7c0.6,0.4,1,1.1,0.9,1.7c-0.1,1.2-1.2,2.4-2.6,2.9l-0.4,0.1l0.3,0.5c0.2,0.4,0.3,0.6,0.3,1.1
                c0,1.1-0.5,1.8-1.6,2.4c-0.6,0.3-0.8,0.4-1.5,0.4c-1,0.1-1.4,0-2.2-0.4c-0.8-0.4-1.4-0.7-1.8-0.8c-0.3,0-0.4,0-1.2,0.5
                c-1.9,1.2-3.4,1.6-4.7,1.1c-0.8-0.4-1.4-1.2-1.4-2.1c0-0.9,0.7-1.9,1.6-2.2c0.6-0.2,1.1-0.5,1.2-0.6c0,0-0.1-0.4-0.2-0.8
                c-0.2-0.4-0.4-1.1-0.6-1.6c-0.3-1.1-0.9-3-1-3.4c-0.1-0.2-0.1-0.3-0.2-0.2c-3.1,1.1-4.7,1.5-7.7,2c-2.1,0.4-3.3,0.5-7.2,0.7
                l-3.5,0.2l0,0.5c0,0.3,0,0.6,0,0.7c-0.1,0.2,0,0.2,0.6,0.2c1.4-0.1,2.5,0.2,3.2,0.9c0.4,0.4,0.8,1.1,0.8,1.6
                c0,0.7-0.4,1.6-1.1,2c-0.6,0.4-2.1,0.8-3.3,0.9c-0.9,0.1-1.1,0.1-1.5-0.1l-0.5-0.2l-0.2,0.3c-0.3,0.6-0.8,1-1.5,1.4
                C40,52.2,40,52.2,39,52.2c-0.8,0-1.2,0-1.7-0.1c-0.8-0.2-2.4-0.8-2.9-1.2c-0.2-0.1-0.4-0.3-0.5-0.3c-0.1,0-0.5,0.3-1,0.6
                c-2.5,1.6-4.3,2.4-5.8,2.3c-0.7,0-1.3-0.2-1.8-0.7c-0.6-0.5-0.8-0.9-0.8-1.8c0-1,0.1-1.4,0.7-2.1c0.5-0.6,1-0.8,2.1-1.1
                c2.2-0.6,4-1.6,5.4-3.1c0.6-0.7,1.4-1.8,1.3-1.9c0,0-0.7-0.1-1.5-0.2c-1.8-0.2-4.4-0.6-5.3-0.8c-0.9-0.2-1.4-0.1-2.6,0.1
                c-5.1,1.2-8.3,3.8-10.8,8.7c-0.7,1.4-1.3,2.7-1.5,3.6c-0.3,1-0.2,2.4,0.2,3.5c1,2.8,3.3,5,5.7,5.5c0.3,0.1,0.6,0.1,0.8,0.1
                l0.2,0L19,62.1c-0.3-2.9,0.4-5.3,1.8-6.8c2-2,5.1-1.8,7.6,0.6c0.4,0.4,0.9,0.9,1.1,1.2c2,2.8,3.2,7.6,2.5,10.5
                c-1,4.2-4.1,7.1-8.7,8.1c-0.7,0.2-1.4,0.2-2.7,0.3C19.7,76.1,18.8,76.1,18.7,76.1z"/>
            </g>
            <g>
              <path className="shadow-primary-textlogo" d="M18.8,74.4c-2.5-0.2-4.4-0.8-6.3-1.9c-1-0.6-1-0.6-2.2-1.7c-2.5-2.3-3.7-4.7-4.5-9.5
                c-0.4-2.6-0.5-6.6-0.2-9.5c0.5-4.5,1.5-8.4,3.4-12.7c1-2.5,2.6-5.4,3.7-7.1c0.2-0.3,0.5-0.9,0.7-1.1c0.2-0.3,0.6-0.8,0.8-1.2
                c0.3-0.4,0.7-1,1-1.4c0.3-0.4,0.6-0.8,0.8-1c0.1-0.2,0.5-0.6,0.7-0.9c2.6-3.3,6.5-7.5,10.2-10.7c0.4-0.4,0.9-0.8,1-0.9
                c0.2-0.1,0.7-0.6,1.3-1.1c0.1-0.1,0.4-0.3,0.6-0.5c0.7-0.6,3.1-2.3,4.3-3.1c3.5-2.3,6.5-3.6,9.8-4.6c1.7-0.5,3-0.7,5-0.8
                c2.4-0.1,4,0.1,6.3,0.7c3.4,0.9,6.9,2.9,9.8,5.8c1.7,1.7,3.6,4.1,5.1,6.4c0.3,0.5,0.6,0.9,0.6,0.9c0,0-0.2-0.7-0.5-1.6
                c-0.4-1.4-0.7-2.7-1.1-4.4c-0.2-1.2-0.4-4.3-0.2-5.1c0.3-1.8,1.7-3.9,3-4.7c0.5-0.3,0.6-0.3,1.6-0.3c1.1-0.1,1.1-0.1,2.6,0.4
                c4.4,1.3,7.7,2.7,11,4.7c2.3,1.4,5,3.5,6.7,5.4c0.4,0.4,0.8,0.9,0.9,1c0.7,0.8,2,2.7,2.8,4.1c0.6,1.1,1,2,1.4,3.3
                c1.4,4,0.7,8.6-1.7,12c-2.1,3.1-5,4.9-8.7,5.5c-1,0.2-3.8,0.3-4.9,0.1c-1-0.1-2.5-0.3-2.6-0.4c-0.1,0-0.2-0.1-0.3-0.1
                c-0.8,0-6.2-1.6-6.9-2.2c-0.1-0.1-0.3-0.1-0.3-0.1c-0.2,0-1.1-0.4-2.1-0.9c-0.9-0.4-1.4-0.7-1.6-0.7c-0.1,0-0.3,0.2-0.4,0.4
                c-0.4,0.4-1.7,1.4-2.1,1.6c-0.1,0.1-0.3,0.2-0.3,0.3c-0.3,0.6,0.6,6.6,1.4,9.2l0.2,0.5l1.3,0c0.7,0,1.6,0,1.9,0.1
                c0.5,0.1,1.7,0.6,2,0.8c0.1,0,0.2,0.2,0.4,0.2c0.3,0.2,0.7,0.7,0.7,1c0,0.6-0.8,1.2-1.8,1.2c-0.5,0-0.6,0-1-0.2
                c-0.5-0.3-1.6-0.8-2.2-0.9c-0.8-0.2-1.3-0.1-1.9,0.4c-1.3,0.9-2.3,1.4-3.1,1.3c-0.6,0-1-0.2-1-0.6c0-0.3,0.2-0.6,0.6-0.7
                c1.2-0.5,2.1-1.2,2.1-1.9c0.1-0.6,0-0.7-0.3-1.6c-0.2-0.4-0.3-0.9-0.3-1c0-0.1,0-0.2-0.1-0.2c0,0-0.1-0.2-0.1-0.3
                c0-0.2-0.1-0.4-0.1-0.4c-0.1,0-0.4-1.2-0.8-2.7c-0.3-1.1-0.3-1.4-0.5-2.3c-0.3-1.7-0.4-2.6-0.6-4.6c-0.1-2-0.2-2.2-0.6-2.6
                c-0.2-0.2-0.6-0.2-0.8,0c-0.3,0.3-0.3,1.7-0.1,3.9c0.1,1,0.2,2.1,0.3,2.3c0.1,0.3,0.1,0.7,0.1,0.9l0,0.4l-1,0.3
                c-1.6,0.6-3.9,1.2-5.7,1.5c-3.5,0.7-8.1,1-12.4,1c-0.7,0-1.3,0-1.4,0c-0.3,0-0.2-0.3,0.5-1.5c0.4-0.7,0.8-1.4,0.9-1.5
                c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.1,0.8-1.9,1-2.5c0.3-0.8,0.8-2.6,1-3.3c0.6-3-0.2-5.5-2.2-6.6c-0.8-0.4-1.3-0.5-2.2-0.5
                c-1.5,0.1-3.5,1-5.9,2.8c-1.1,0.8-1,0.8-1.8,1.7c-0.8,0.9-1.3,1.7-1.9,3c-0.6,1.2-0.5,1.5,0.3,1.4c0.4,0,0.7-0.3,1.1-1.1
                c0.4-0.8,1.4-2.2,2.1-2.8c0.3-0.3,0.6-0.6,0.7-0.7c0.7-0.7,2.3-1.7,3.3-2.1c1.2-0.5,2.5-0.7,3.3-0.5c0.7,0.2,1.5,1.1,1.7,2
                c0.5,1.7,0.1,4.1-0.9,6.6c-0.6,1.5-1.6,3.5-2.2,4.3c-0.1,0.2-0.3,0.5-0.4,0.6c-0.1,0.1-0.5,0.7-1,1.4
                c-0.5,0.7-1.1,1.4-1.2,1.6c-0.2,0.2-0.4,0.5-0.5,0.6c-0.4,0.5-1.1,1.7-1.3,2.2c-0.2,0.6-0.3,1,0,1.1c0.1,0,0.6,0.1,1.1,0.2
                c0.5,0.1,1.2,0.2,1.5,0.3c0.6,0.1,1.5,0.5,1.6,0.8c0.1,0.2,0.1,0.7-0.1,1c-0.1,0.3-0.6,0.7-0.8,0.7c-0.1,0-0.1,0.1-0.1,0.1
                c0,0.2-1.5,0.2-2.2,0c-0.6-0.1-1.7-0.6-1.8-0.7c0-0.1-0.1-0.1-0.9-0.5c-0.4-0.2-0.6-0.2-1-0.2c-0.5,0-1.4,0.4-1.8,0.7
                c-0.3,0.3-1.4,1-1.9,1.2c-0.3,0.1-0.7,0.3-0.9,0.4c-1,0.5-2.2,0.7-2.7,0.4c-0.4-0.2-0.6-0.7-0.6-1c0-0.1,0.2-0.4,0.4-0.6
                c0.3-0.4,0.4-0.4,1.2-0.7c2-0.6,3.2-1.1,4.2-1.8c0.2-0.2,0.5-0.3,0.7-0.4c0.1-0.1,0.3-0.1,0.3-0.2c0,0,0.1-0.2,0.3-0.4
                c0.9-0.9,1.2-1.2,1.7-2c0.7-1,1.1-1.8,1.4-2.7l0.2-0.7l-1-0.1c-0.6-0.1-1.1-0.1-1.2-0.2c-0.1,0-0.4-0.1-0.6-0.1
                c-0.2,0-0.5,0-0.7-0.1c-0.5-0.1-1.3-0.2-3-0.4c-0.7-0.1-1.3-0.2-1.3-0.2c-0.1,0-0.2-0.1-0.4-0.1s-0.6-0.1-0.9-0.1
                c-0.8-0.2-1.2-0.2-2.4,0.1c-2.8,0.5-5.5,1.7-7.7,3.4c-0.3,0.2-0.6,0.4-0.6,0.5c-0.3,0.2-2.4,2.8-2.8,3.3
                c-1.6,2.5-2.8,5.2-3.2,6.9c-0.1,0.8-0.1,2.4,0.2,3.3c0.8,2.9,3.1,5.6,5.7,6.8c0.4,0.2,1.2,0.4,2,0.6c0.5,0.1,2.6,0.2,2.7,0.1
                c0,0,0-0.4-0.1-0.8c-0.2-1.1-0.4-3.8-0.3-4.5c0.2-1.9,0.9-3.2,2-3.8c0.5-0.3,1.4-0.4,2.2-0.2c0.7,0.1,1.1,0.3,1.8,0.8
                c1.5,1.1,2.6,2.8,3.3,5.1c0.5,1.6,0.6,2,0.6,3.6c0.1,1.4,0.1,1.6-0.1,2.2c-0.8,2.9-2.8,5.1-5.7,6.1
                C22.9,74.2,20.6,74.5,18.8,74.4z"/>
              <path className="shadow-primary-textlogo" d="M43.6,49.1c-0.5,0-0.7-0.2-0.9-0.5c-0.1-0.2-0.2-0.4-0.3-0.4c-0.1,0-0.3-0.3-0.5-0.5
                c-0.6-0.7-1.5-1.1-2.6-1.4c-0.3-0.1-0.7-0.3-0.7-0.4c0-0.1,0.4-0.7,1.2-1.7l0.6-0.8l0.7,0c0.4,0,0.8,0,0.9,0
                c0.1,0,0.3,0,0.5,0.1c0.2,0.1,0.2,0.2,0.3,0.6c0,0.4-0.2,1.3-0.5,1.4c-0.2,0.1-0.1,0.4,0.2,0.6c0.2,0.2,0.4,0.2,1.6,0.1
                c1.3-0.1,2,0,2.3,0.2c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0,0.5,0.5,0.6,1c0,0.3,0,0.4-0.2,0.6c-0.3,0.3-1.5,0.7-3,0.9
                C44,49.1,43.7,49.1,43.6,49.1z"/>
              <path className="shadow-primary-textlogo" d="M73.7,45.6c-0.1,0-0.3-0.1-0.3-0.1c0,0,0-0.1-0.1-0.1c-0.1,0-0.3-0.1-0.6-0.3c-0.3-0.2-0.6-0.3-0.7-0.2
                c-0.1,0-0.3,0-0.5,0c-0.2-0.1-0.7-0.1-1.1-0.1c-0.6,0-0.7-0.1-0.9-0.3c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.1,0-0.2-0.1-0.3
                c0,0-0.1-0.3-0.2-0.6c0-0.3-0.1-0.8-0.2-1.1c-0.3-1.5-0.6-3.9-0.5-4.2c0-0.1,0.2-0.3,0.3-0.4c0.2-0.1,0.4-0.3,0.6-0.5
                c0.4-0.3,0.7-0.4,1.2-0.1c0.2,0.1,0.4,0.2,0.5,0.3c0.3,0.2,0.4,1,0.5,3.4c0,1.2,0.1,2.3,0.2,2.4c0.1,0.2,0.2,0.2,1.1,0.1
                c1.6-0.2,3.3-0.1,4,0.2c0.7,0.3,0.7,0.8,0.1,1.5c-0.5,0.5-1,0.7-1.9,0.8C74.4,45.6,73.9,45.6,73.7,45.6z"/>
            </g>
            <g>
              <path className="shadow-secondary-textlogo" d="M19.7,74.4c0,0-0.3,0-0.6,0c-1.6-0.1-2.5-0.3-4.5-0.9c-0.2-0.1-0.4-0.2-0.6-0.2c-0.1-0.1-0.3-0.1-0.3-0.1
                c-0.1,0-0.4-0.2-1.5-0.9c-0.1,0-0.2-0.1-0.3-0.2s-0.3-0.2-0.5-0.3c-0.4-0.3-1.8-1.5-1.8-1.6c0,0-0.2-0.3-0.5-0.7
                c-0.3-0.3-0.6-0.7-0.6-0.7c0-0.1-0.2-0.3-0.3-0.5c-0.4-0.6-0.6-1-0.6-1.1c0,0-0.1-0.3-0.2-0.5c-0.5-0.9-1.2-3.2-1.3-4.5
                c0-0.2-0.1-0.5-0.1-0.6c-0.4-1.7-0.6-7.6-0.3-9.5c0-0.1,0.1-0.4,0.1-0.6c0.1-0.9,0.7-4.4,0.9-5.2c0.1-0.2,0.2-0.7,0.3-1.2
                c0.1-0.4,0.2-0.8,0.3-0.9c0,0,0.1-0.3,0.2-0.6c0.1-0.3,0.2-0.7,0.3-0.9c0.1-0.2,0.2-0.6,0.3-0.8C7.9,41.7,8,41.5,8,41.4
                c0.1-0.2,0.2-0.6,0.6-1.5c0.5-1.2,0.7-1.8,0.8-1.9c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.3,0.2-0.5c0.1-0.2,0.3-0.6,0.4-0.9
                c0.3-0.6,0.3-0.7,0.7-1.3c0.1-0.2,0.2-0.4,0.2-0.4c0,0,0.4-0.7,0.8-1.4c0.5-0.8,0.8-1.4,0.8-1.5c0,0,0.1-0.2,0.3-0.3
                c0.1-0.1,0.3-0.3,0.2-0.4c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.2-0.2c0,0,0.1-0.2,0.2-0.4c0.1-0.2,0.3-0.3,0.2-0.4
                c0-0.1,0-0.1,0.1-0.1c0,0,0.2-0.2,0.3-0.4c0.1-0.2,0.3-0.4,0.3-0.5c0.1,0,0.1-0.1,0.1-0.1c0,0,0.1-0.3,0.3-0.5
                c0.2-0.2,0.3-0.4,0.3-0.5c0,0,0.1-0.2,0.2-0.3c0.1-0.1,0.3-0.4,0.5-0.6c0.1-0.2,0.4-0.5,0.6-0.7c0.2-0.2,0.4-0.5,0.5-0.7
                c0.4-0.5,1.7-2.1,2.7-3.2c1.2-1.4,2.3-2.5,4-4.1c0.1-0.1,0.4-0.4,0.7-0.7c0.9-0.9,2.5-2.4,3-2.7c0.1-0.1,0.3-0.2,0.4-0.3
                c0.1-0.1,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.3,0.6-0.5c0.5-0.5,3.3-2.6,4.7-3.5c1.3-0.8,3.1-1.9,3.3-1.9c0.1,0,0.1,0.1,0.1,0.2
                c0,0.1,0,0.1,0.1-0.1c0-0.1,0.2-0.3,0.5-0.5c0.5-0.3,0.5-0.3,1.4-0.6c0.3-0.1,0.8-0.3,1-0.5c0.3-0.1,0.6-0.2,0.7-0.3
                c0.1,0,0.3-0.1,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.8-0.2,1.6-0.5c2.4-0.8,5-1.1,7.2-1c1.4,0.1,3,0.3,3.6,0.5
                c1.5,0.4,2.2,0.6,2.2,0.7c0,0,0.1,0.1,0.2,0.1c0.3,0,2.6,1.1,3.7,1.7c0.6,0.3,1.3,0.8,1.6,1.1c0.3,0.2,0.6,0.4,0.6,0.4
                c0.1,0,2.9,2.5,2.9,2.6c0,0.1,0,0.1,0.1,0.1c0.1,0,1.4,1.4,2.2,2.4l0.5,0.6L67.8,15l-0.2,0.3l0.2-0.2l0.2-0.2l0.1,0.2
                c0.1,0.1,0.2,0.3,0.2,0.3c0,0.1,0.1,0.2,0.2,0.3c0.5,0.7,0.7,1,1.5,2.3c0.4,0.7,0.5,0.9,0.6,0.7c0-0.1,0-0.3,0-0.3
                c0-0.1-0.1-0.5-0.1-1c0-0.5-0.1-1.1-0.2-1.5c-0.1-0.7-0.1-3.1,0.1-4.4c0.2-1.7,0.7-3.7,1.3-5.1c0.5-1.1,1.3-2.4,2.1-3.2
                l0.7-0.8l1.3,0.3C76.4,3,77,3.2,77.1,3.2c0.1,0,0.4,0.1,0.6,0.2c0.2,0.1,0.5,0.2,0.6,0.2c0.1,0,0.4,0.1,0.5,0.2
                c0.9,0.4,1.5,0.6,1.5,0.6c0.1,0,3.4,1.5,3.8,1.7c0.2,0.1,0.5,0.3,0.8,0.4c0.3,0.2,0.6,0.3,0.6,0.4c0.2,0.1,0.5,0.2,0.8,0.5
                c0.2,0.1,0.4,0.2,0.4,0.2c0,0,0.2,0.1,0.3,0.2c0.1,0.1,0.6,0.4,1,0.7c0.4,0.3,0.9,0.6,1.2,0.8c0.2,0.2,0.5,0.4,0.6,0.4
                c0.4,0.2,2.5,2,3.1,2.8c0.2,0.2,0.5,0.5,0.5,0.6c1,1.1,2.4,2.9,3,4c0.1,0.2,0.3,0.5,0.3,0.6c0.1,0.2,0.7,1.3,0.8,1.5
                c0,0.1,0.1,0.3,0.2,0.5c0.4,0.8,1,2.6,1.2,3.7c0.1,0.5,0.2,2.8,0.2,3.3c0,0.2-0.1,0.5-0.1,0.8c-0.1,1.2-0.9,3.6-1.5,4.6
                c-0.2,0.4-0.4,0.6-0.4,0.8c-0.3,0.8-3,3.8-3.4,3.8c0,0-0.2,0.1-0.3,0.2c-0.3,0.3-0.8,0.5-1.2,0.7c-0.3,0.1-0.6,0.1-2.7,0
                c-1.8-0.1-5.4-0.7-7-1.1c-0.2-0.1-0.7-0.2-1.1-0.3c-1.3-0.3-3-0.9-5.1-1.7c-0.5-0.2-2.5-1.1-2.6-1.1c0,0-0.3-0.2-0.6-0.3
                c-1-0.5-3.1-1.8-4.1-2.5c-0.9-0.6-1-0.7-1.3-0.7c-0.4,0-1,0.5-1.2,1.1c-0.4,0.9-0.5,2.7-0.3,4.6c0.1,0.6,0.2,1.7,0.2,2.3
                c0.1,1.5,0.3,3.1,0.5,3.9c0,0.2,0.1,0.5,0.1,0.7c0,0.2,0.2,1.1,0.5,2.3c0.1,0.2,0.2,0.7,0.3,1.1c0.1,0.4,0.2,0.7,0.2,0.7
                c0,0,0.6,0,1.3,0c0.9,0,1.4,0,2,0.1c0.8,0.2,1.5,0.5,1.5,0.6c0,0.1-0.3,0.2-0.6,0.3c-0.5,0.2-0.8,0.3-1.8,0.3
                c-1.1,0.1-2.7-0.1-3-0.2c-0.1-0.1-0.5-1-0.7-1.9c-0.1-0.2-0.3-0.9-0.5-1.6c-0.5-1.5-1.2-4.3-1.5-6c-0.2-0.9-0.2-1.6-0.3-3.7
                c-0.1-2.5-0.1-2.6-0.3-3.2c-0.2-0.4-0.3-0.6-0.6-0.8c-0.3-0.2-0.4-0.3-1-0.2c-0.6,0-0.7,0-1.5,0.6c-3.3,2.1-6.5,3.4-10.5,4.2
                c-1.6,0.3-5.4,0.7-5.6,0.5c0-0.1,0-0.2,0.2-0.9c0.1-0.2,0.1-0.3,0.1-0.4c0-0.1,0-0.2,0.1-0.2c0,0,0.1-0.2,0.1-0.3
                c0-0.1,0.1-0.6,0.2-1c0.2-0.9,0.2-1.4,0.2-2.5c-0.1-1.2-0.1-1.5-0.6-2.4c-0.5-1-1.2-1.6-2.3-2.1c-0.5-0.2-0.7-0.3-1.3-0.2
                c-0.8,0-1.7,0.2-1.8,0.3c0,0-0.1,0.1-0.2,0.1c-0.2,0-0.5,0.1-1.3,0.6c-0.2,0.1-0.4,0.2-0.6,0.3c-0.1,0.1-0.4,0.2-0.6,0.3
                c-0.2,0.1-0.5,0.3-0.6,0.4c-0.7,0.4-1.5,1-1.5,1.1c0,0,0,0.1-0.1,0.1c-0.2,0-1.3,1.1-1.9,1.9c-0.1,0.2-0.3,0.4-0.4,0.5
                c-0.3,0.4-1.1,2-1.3,2.5c-0.1,0.4-0.1,0.5,0,0.7c0.3,0.4,1.2,0.1,1.5-0.6c0.2-0.5,0.9-1.6,1.4-2.3c0.5-0.7,0.8-1,1.5-1.5
                c0.2-0.2,0.3-0.3,0.3-0.4c0,0,0-0.1,0.1-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.3-0.3,0.6-0.4c0.2-0.1,0.5-0.3,0.6-0.4
                c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2-0.1,0.4-0.1c1.3-0.7,1.9-0.9,3.1-1c0.7,0,0.9,0,1.3,0.2c0.3,0.1,0.6,0.3,0.6,0.4
                c0.1,0.1,0.2,0.3,0.3,0.4c0.4,0.4,0.8,2.2,0.7,3.6c-0.1,1.2-0.7,3.2-1.4,4.7c-0.1,0.1-0.2,0.5-0.4,0.8
                c-0.1,0.3-0.3,0.7-0.4,0.9c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.3-1.6,2.6-1.9,3c-0.1,0.1-0.3,0.4-0.5,0.6
                c-0.2,0.2-0.4,0.5-0.4,0.6c-0.1,0.1-0.4,0.5-1,1.3c-0.1,0.2-0.3,0.4-0.5,0.6c-0.1,0.2-0.2,0.4-0.2,0.4c0,0-0.1,0.2-0.2,0.3
                c-0.1,0.1-0.3,0.4-0.3,0.5c-0.1,0.2-0.2,0.3-0.2,0.4c0,0-0.1,0.3-0.2,0.5c-0.1,0.4-0.1,0.5,0,0.7c0.1,0.1,0.1,0.2,0.2,0.2
                c0,0,0.3,0,0.7,0.1c0.3,0.1,0.8,0.1,1,0.2c0.6,0.1,1.4,0.3,1.5,0.4c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.3,0.1,0.5,0.3
                c0.4,0.4,0.3,0.4-0.5,0.6c-1.9,0.3-3.4-0.2-4.3-1.4c-0.3-0.4-0.4-0.6-0.4-1l-0.1-0.5l1-1.7c2-3.5,3.3-6.3,3.9-8.2
                c0.6-2,0.3-3-0.9-3.1c-0.8-0.1-2,0.3-2.6,1c-0.6,0.5-2.1,1.6-2.9,2c-1.3,0.7-3.1,1.3-4.8,1.6c-0.5,0.1-2,0.3-3.4,0.4
                c-3.2,0.3-4.8,0.7-5.8,1.3c-1.1,0.7-2.5,2.1-3.5,3.5c-0.3,0.4-0.5,0.7-0.5,0.7c-0.1,0.1-1.8,2.9-2.1,3.5
                c-0.1,0.1-0.3,0.5-0.5,0.9c-0.5,0.8-0.6,1.1-0.8,1.7c-0.1,0.3-0.3,0.6-0.3,0.7c-0.3,0.7-1,2.3-1,2.4c0,0.1,0,0.2-0.1,0.3
                c-0.4,0.8-0.5,2.1-0.4,3.2c0.1,1,0.2,1.6,0.3,1.8c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.3,0.2,0.3
                c0,0.1,0.2,0.4,0.4,0.9c1,1.8,2.4,3.2,4.8,4.6c1.9,1.1,2.6,1.5,3.6,1.7c1.4,0.4,2.6,0.1,3.4-0.9c0.7-0.9,1.1-2,1.3-3.7
                c0.2-1.1,0.2-3.4,0-4.8c-0.1-0.7-0.1-1.2,0-1.2c0.2,0,0.9,0.3,1.5,0.6c1,0.6,1.9,1.5,2.6,2.9c0.7,1.2,0.8,1.4,1.2,3
                c0.3,1.1,0.4,1.4,0.5,2.8c0.1,1.1,0.1,1.3-0.1,2c-0.3,1.2-0.7,2.4-0.9,2.5c-0.1,0-0.1,0.1-0.1,0.3c-0.1,0.2-1.4,1.9-1.6,1.9
                c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.5,0.4-0.8,0.6c-0.4,0.2-0.7,0.4-0.8,0.5c-0.5,0.3-1.9,0.8-2.4,0.9c-0.1,0-0.3,0.1-0.4,0.1
                C22.6,74.2,19.8,74.5,19.7,74.4z"/>
            </g>
            <g>
              <path className="main-textlogo" d="M21.3,74.4c0,0-0.6,0-1.4,0c-0.7,0-1.4,0-1.4,0c-0.1,0-0.5-0.1-1-0.2c-0.5-0.1-1.1-0.2-1.3-0.2
                c-0.4-0.1-0.4-0.1-0.6-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.2-0.1-0.2-0.1c0,0-0.2,0-0.4-0.1c-0.2-0.1-0.5-0.2-0.8-0.3
                c-0.2-0.1-0.4-0.2-0.4-0.3c0,0,0-0.1-0.1,0c-0.1,0-0.1,0-0.1,0c0-0.1,0-0.1-0.1,0c-0.2,0.1-0.5-0.1-0.3-0.2
                c0.1-0.1,0.1-0.1,0,0c-0.2,0.1-0.4,0-0.2-0.2c0.1-0.1,0.1-0.1,0-0.1c-0.2,0.1-1-0.4-1.4-0.8c-0.3-0.3-0.4-0.4-0.2-0.6
                c0.1-0.1,0.1-0.1,0-0.1c-0.1,0-0.2,0-0.1,0.1s0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0c-0.1-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.1,0-0.1
                c-0.1,0.1-0.1-0.1,0.1-0.2c0.2-0.2,0.1-0.2-0.1,0c-0.1,0.1-0.2,0.1-0.4,0c-0.2-0.1-0.2-0.2-0.2-0.3c0-0.1,0-0.2-0.1-0.2
                c-0.1,0-0.1,0,0,0c0,0.1,0,0.1,0,0.1c-0.1,0-0.1,0-0.1-0.1c0,0-0.2-0.3-0.5-0.6c-0.4-0.4-0.4-0.5-0.4-0.6c0-0.1,0.1-0.1,0-0.1
                c-0.1,0.1-0.3,0-0.3-0.2c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.1-0.1
                c0-0.1,0-0.1-0.1-0.1c-0.1,0-0.1,0,0-0.1c0-0.1,0-0.1,0-0.1c-0.1,0.1-0.2-0.1-0.2-0.4c0-0.1,0-0.2-0.1-0.2
                c0,0-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.2,0-0.2c0,0,0.1,0,0-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0.1-0.1,0.1-0.1,0
                c0-0.2,0-0.3-0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.1c-0.1,0-0.1,0-0.2-0.2c0-0.1-0.1-0.4-0.2-0.6
                C6.4,64.1,6,62.5,6,62.1c0-0.2-0.1-0.5-0.1-0.6c-0.2-0.8-0.6-6.5-0.4-6.7c0.1-0.1,0.1-0.2,0-0.4c-0.1-0.2-0.1-0.3,0-0.4
                c0.1-0.1,0.1-0.5,0.1-0.9c0-0.6,0-0.9,0.2-1.1c0.1-0.1,0.1-0.2,0-0.2c-0.1,0.1-0.1,0-0.1-0.1c0-0.2,0-0.2,0.1-0.1
                c0.1,0.1,0.1,0.1,0,0c-0.1-0.2-0.1-0.2,0-0.9c0-0.3,0.1-0.5,0.2-0.5c0,0,0.1,0,0-0.1C5.9,49.9,6,49,6.2,47.8
                c0.1-0.3,0.2-0.9,0.3-1.2c0.1-0.3,0.2-0.5,0.2-0.5c0.1,0,0.1,0,0-0.1c-0.1-0.1,0-0.2,0.2-1.2c0.1-0.3,0.2-0.8,0.3-1.1
                c0.2-0.8,0.3-1,0.4-0.9c0,0,0.1,0,0.1-0.1c0-0.1,0-0.1,0.1,0c0.1,0.1,0.1,0.1,0.1,0c0-0.1-0.1-0.1-0.1-0.1
                c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0.1-0.1c0,0,0.1,0,0-0.1c-0.1-0.1,0.1-0.7,0.2-0.6c0,0,0,0,0,0c0-0.1,0-0.2,0-0.3
                c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0,0c0-0.1,0-0.2,0-0.3c0-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.2
                c0,0,0.1,0,0.1,0c0.1,0.1,0.1,0.1,0.1,0c0-0.1,0-0.3,0.1-0.5c0.1-0.2,0.1-0.4,0.1-0.5c0-0.1,0-0.2,0.1-0.2
                c0,0,0.2-0.3,0.3-0.6c0.2-0.3,0.3-0.6,0.4-0.6c0.1,0,0.1,0,0.1-0.2c0-0.2,0-0.2,0.1-0.2c0.1,0,0.1,0,0.1,0
                c-0.1-0.1,0.2-0.8,0.3-0.8c0,0,0.1,0,0-0.1c-0.1-0.1,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1-0.1c-0.1-0.1,0.1-0.5,0.2-0.4
                c0,0,0.1,0,0.1-0.1c0-0.1,0.1-0.4,0.3-0.7c0.2-0.3,0.3-0.5,0.3-0.6c0,0,0.1-0.2,0.2-0.4c0.1-0.2,0.2-0.3,0.2-0.3
                c0-0.1,0.7-1.1,0.8-1.1c0,0,0.1,0,0-0.1c0-0.1,0.1-0.3,0.2-0.5c0.1-0.2,0.2-0.4,0.3-0.3c0,0.1,0.1,0,0.1-0.2
                c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.1,0.2-0.2,0.2-0.3c0,0,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.1,0.1,0c0,0.1,0,0.2,0,0.2
                c0,0,0.1-0.1,0-0.2c0-0.3,0.2-0.6,0.3-0.5c0,0,0.1,0,0,0c0-0.1,0-0.1,0.1-0.2c0.1-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.3,0.3-0.5
                c0.3-0.4,0.4-0.5,0.4-0.3c0,0,0,0.1-0.1,0c0,0-0.1,0-0.1,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.1,0-0.2,0.1c0,0,0,0,0.1,0
                c0.1,0,0.2-0.1,0.3-0.2s0.1-0.1,0.2-0.1c0,0,0,0,0-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0.1-0.1,0.7-1,1-1.3c0.1-0.1,0.1-0.2,0.1-0.3
                c0,0,0.1-0.2,0.3-0.4c0.2-0.2,0.3-0.4,0.3-0.4c0-0.1,0-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1c0,0.1,0,0.1,0,0.1
                c0.1,0,0.1-0.1,0.1-0.2c0-0.1,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1-0.1c-0.1-0.1,0.2-0.4,0.4-0.4c0.1,0,0.2,0,0.2,0.1
                c0.1,0.1,0.3,0.1,0.3,0c0,0-0.1-0.1-0.1,0c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.1,0,0,0.1c0,0.1,0,0.1-0.1,0.1
                c-0.1,0-0.1,0-0.1-0.1c0-0.1,1-1.2,1.1-1.2c0,0,0.1,0,0-0.1c-0.1-0.1,0.7-1,0.8-0.9c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.1,0,0
                c-0.1,0.1-0.2,0.1-0.2,0c0-0.1,0.1-0.1,0.2-0.2c0.1,0,0.1-0.1,0.1-0.1c-0.1-0.2,0.5-0.7,0.7-0.7c0.1,0,0.1,0,0,0
                c0-0.1,0.1-0.3,0.3-0.5c0.2-0.2,0.4-0.4,0.4-0.4c0,0,0.2-0.2,0.4-0.4c0.2-0.2,0.4-0.4,0.4-0.4c0,0,0.1,0,0.2-0.1
                c0.2-0.1,0.2-0.2,0.1,0c0,0.1,0,0.1,0,0c0.1-0.1,0.1-0.1,0.1,0.1c0,0,0,0,0.1,0c0.1,0,0-0.1,0-0.2c-0.2-0.2-0.2-0.2,0.2-0.6
                c0.2-0.2,0.4-0.3,0.5-0.3c0.1,0,0.1,0,0.1,0c-0.1-0.1,0.4-0.7,0.7-0.8c0.1-0.1,0.2-0.2,0.2-0.2c0-0.1,0-0.1,0.2-0.1
                c0.1,0,0.2,0,0.1,0c-0.1-0.1-0.1-0.1,0.1-0.2c0.1-0.1,0.3-0.2,0.3-0.3c0.1-0.2,2.8-2.7,2.9-2.7c0,0,0.1,0.2,0.1,0.4
                c0,0.2,0.1,0.4,0.1,0.4c0.1,0,0.1-0.2,0.1-0.4c0-0.3,0-0.5,0.2-0.7c0.1-0.2,0.3-0.3,0.3-0.3c0,0,0.3-0.2,0.5-0.4
                c0.3-0.2,0.4-0.3,0.4-0.3c-0.1,0.1,0,0,0.3-0.4c0-0.1,0.1-0.1,0.2-0.1s0.1,0,0.1,0c-0.1-0.2,2.2-1.9,3.9-3
                c1.3-0.8,3.1-1.9,3.3-1.9c0.1,0,0.1,0.1,0.2,0.2c0,0.1,0.1,0.1,0.1,0c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.1,0,0-0.1
                c-0.1,0,0-0.1,0.1-0.2c0.2-0.1,0.6-0.1,0.4,0c-0.1,0-0.1,0.1,0,0.1c0.1,0,0.1-0.1,0-0.3c-0.1-0.1,0.1-0.2,0.4-0.2
                c0.2,0,0.3,0,0.2-0.1c-0.1,0-0.1-0.1,0-0.1c0.1,0,0.4-0.2,0.8-0.3c0.3-0.1,0.6-0.2,0.7-0.2c0,0,0.1,0,0.1-0.1
                c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.1,0c0-0.1,0.7-0.4,1.1-0.5c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.5-0.2,0.9-0.3
                c0.4-0.1,0.8-0.2,0.8-0.3C44,5.4,46,5,47.5,4.8c1.4-0.2,1.7-0.2,3.1-0.1c1.8,0.1,1.9,0.2,2.5,0.3c0.3,0.1,0.6,0.1,0.7,0.1
                c0.2,0,0.2,0,0.1,0.1c-0.1,0.1-0.1,0.1,0.1,0c0.2-0.1,0.6,0,1.1,0.2c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.4,0.1,0.6,0.2
                C56.3,5.8,56.7,6,56.9,6c1.4,0.5,5,2.5,5,2.8c0,0.1,0,0.1-0.1,0c0,0-0.1,0-0.1,0.1c0,0.1,0,0.1,0.1,0.1c0.1,0,0.1,0,0.1,0.1
                c0,0.1,0,0.1,0.1,0.1C62,9.1,62,9.1,62,9c-0.1-0.2,0.1-0.1,0.5,0.2c0.2,0.2,0.4,0.3,0.5,0.4c0.1,0.1,0.2,0.2,0.1,0.2
                c0,0-0.2,0-0.3-0.1c-0.2-0.1-0.2-0.2-0.3-0.1c0,0.1,0,0.1,0,0c0.1,0,0.1,0,0.2,0c0,0,0,0.1-0.1,0.1c-0.1,0-0.1,0,0,0.1
                c0.1,0,0.2,0.1,0.4,0c0.3-0.1,0.4,0,0.3,0.2c-0.1,0.1-0.1,0.1,0,0c0.1-0.1,0.2-0.1,0.2-0.1c0.2,0.3,0.3,0.4,0.2,0.4
                c0,0-0.2,0-0.2,0.1c-0.1,0.1-0.1,0.1,0.1,0.1c0.1,0,0.3-0.1,0.3-0.1c0,0,0.2,0.1,0.4,0.2c0.3,0.3,0.4,0.4,0.3,0.5c0,0,0,0,0,0
                c0.1,0,0.2,0.1,0.4,0.3c0.1,0.2,0.3,0.3,0.3,0.2c0,0,0.1,0,0.1,0c0,0.1,0,0.1,0.1,0c0.1-0.1,1.3,1.2,2.2,2.3l0.5,0.6L67.8,15
                c-0.2,0.2-0.2,0.2,0,0.4c0.2,0.2,0.2,0.2,0.3,0c0-0.1,0-0.2,0-0.3c-0.1-0.1-0.1-0.1,0-0.2c0.1-0.1,0.2,0,0.2,0.2
                c0,0.1,0.1,0.2,0.1,0.2c0.1,0,0.1,0,0.1,0.1c0,0,0.1,0.3,0.3,0.5c0.2,0.2,0.3,0.4,0.3,0.5c0,0.1,0,0.1,0,0.1
                c0.1-0.1,0.4,0.5,0.3,0.6c0,0,0,0.1,0,0c0.2-0.1,0.2,0.2,0,0.3c-0.1,0.1-0.1,0.1,0,0.1c0.1-0.1,0.1,0,0.1,0.1
                c0,0.1,0,0.2,0,0.3c0.1,0,0.1,0,0-0.1c0-0.1,0-0.1,0.1-0.1c0.2,0.1,0.2,0,0-0.2c-0.1-0.1-0.1-0.2-0.1-0.2
                c0.1,0,0.6,0.8,0.5,0.9c0,0.1,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.1,0,0,0c0,0.1,0,0.2-0.1,0.3
                c-0.1,0.1-0.1,0.2,0,0.1c0.1-0.1,0.2-0.1,0.3,0.1c0.1,0.1,0.1,0.2,0.2,0.1c0-0.1,0.1-0.1,0.1,0.1c0,0.1,0.1,0.2,0.2,0.2
                c0,0,0-0.1-0.1-0.2c-0.2-0.2-0.2-0.4,0-0.3c0.1,0,0.1,0,0-0.1c-0.1-0.1-0.1-0.3-0.1-0.6c0-0.2,0-0.5-0.1-0.5
                c-0.1-0.1-0.1-0.7,0-0.8c0.1,0,0.1-0.1,0-0.1c-0.1-0.1-0.1-0.3-0.2-1.2c-0.1-1.3,0-3.2,0.1-3.8c0-0.2,0.1-0.5,0.1-0.6
                c0-0.1,0-0.3,0.1-0.3c0,0,0.1-0.2,0-0.3c0-0.1,0-0.3,0.1-0.4c0-0.1,0.1-0.3,0.1-0.4c0-0.3,0.2-1,0.3-1.3
                c0.1-0.1,0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.3,0.2-0.3c0.1,0,0.1,0,0,0c0-0.1,0-0.2,0-0.4c0.1-0.3,0.7-1.6,0.8-1.6
                c0,0,0.2-0.3,0.3-0.5c0.2-0.3,0.3-0.5,0.4-0.4c0.1,0,0.1,0,0,0c-0.1-0.1-0.1-0.1,0-0.3c0.3-0.4,1-1.2,1.1-1.2
                c0.1,0,0.1-0.1,0.2-0.2l0.1-0.2L75,2.6c0.3,0.1,0.6,0.2,0.8,0.2c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.1,0.2,0
                c0-0.1,0-0.1,0.1,0c0.1,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.2,0c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.3,0.1,0.5,0.2
                c0.3,0.1,0.4,0.1,0.2,0.2c-0.2,0.2-0.2,0.2,0,0.1c0.3-0.1,0.3-0.1,0.3,0c0,0.1,0,0.1,0.1,0c0.1-0.1,0.2-0.1,0.4,0
                c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0c0.1-0.1,0.4,0,0.3,0.1c-0.1,0.1,0.1,0.4,0.3,0.4
                c0.1,0,0-0.1-0.1-0.2c-0.2-0.2-0.1-0.3,0.1-0.1c0.1,0.1,0.2,0.1,0.1,0c0-0.1,0.2,0,0.5,0.1c0.3,0.1,0.5,0.2,0.5,0.3
                c0,0,0,0.1,0.1,0c0.1-0.1,0.5,0.2,0.4,0.3c0,0,0,0.1,0.1,0.1c0.1,0,0.1,0,0.1-0.1c0,0,0.1,0,0.2,0c0.1,0.1,0.2,0.1,0.2,0.2
                c0,0.1,0,0.1,0.1,0c0.1,0,0.3,0,0.5,0.1C83,5.5,83.1,5.6,83,5.7c-0.1,0.1-0.1,0.1,0.1,0c0.1-0.1,0.2-0.1,0.4,0
                c0.1,0.1,0.2,0.2,0.3,0.2c0,0.1,0.1,0.1,0.1,0c0,0,0.1,0,0.3,0.1c0.2,0.1,0.3,0.1,0.3,0.1c0,0,0.1,0,0.3,0.2
                c0.2,0.1,0.4,0.2,0.4,0.2c0.1,0,0.1,0,0,0.1c0,0.1,0,0.1,0,0c0.1,0,0.3,0.1,0.6,0.3c0.3,0.2,0.7,0.4,0.8,0.5s0.3,0.2,0.2,0.2
                c0,0.1,0,0.1,0,0C87,7.6,87.7,8,87.6,8.1c0,0-0.1,0-0.2-0.1c-0.2-0.1-0.2-0.1-0.3-0.1C86.9,8,86.9,8,87,8c0.1,0,0.2,0,0.3,0.1
                c0.1,0.1,0.2,0.1,0.3,0.1c0.1-0.1,0.1,0,0.7,0.4c0.2,0.2,0.4,0.3,0.5,0.3c0.1,0,0.2,0.1,0.3,0.2l0.2,0.2l-0.2-0.2
                C88.9,9,88.9,9,88.9,9c0,0.1,0.2,0.2,0.4,0.2c0.2,0,1.4,1.1,1.4,1.2c0,0,0,0.1,0.1,0c0.2-0.1,0.5,0.2,0.4,0.3c0,0.1,0,0.1,0,0
                c0.1,0,0.1,0,0.1,0c0,0,0.1,0.1,0.2,0.1c0.1,0,0.3,0.2,0.4,0.4c0.1,0.2,0.2,0.3,0.3,0.3c0.1,0,0.1,0,0.1,0.1
                c0,0.1,0.2,0.2,0.3,0.4c0.2,0.2,0.3,0.3,0.3,0.4c0,0,0,0.1,0.1,0c0.1,0,0.1,0,0.1,0.1c-0.1,0.2-0.4,0.2-0.4-0.1
                c0-0.2-0.1-0.3-0.2-0.2c0,0.1,0,0.1,0,0.1c0.1,0,0.1,0,0,0.2c-0.1,0.1-0.1,0.2,0,0.1c0.1,0,0.1,0,0.1,0c0.1,0.1,0.2,0.1,0.4,0
                c0.1-0.1,0.6,0.5,0.7,0.6c0,0,0.1,0.1,0.2,0.2c0.2,0.2,1.1,1.2,1,1.2c0,0,0,0,0,0.1c0.3,0.2,0.7,0.8,0.7,1c0,0.1,0,0.2,0,0.1
                c0.1-0.1,0.2,0,0.2,0.2c0,0.1,0,0.1,0,0c0.1,0,0.1,0,0.2,0.1c0,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0.3,0.2,0.4
                c0,0.1,0,0.1,0,0.1c0.1,0,0.1,0.1,0.1,0.1c0,0.1,0,0.1,0,0.1c0.1-0.1,0.5,0.7,0.5,0.8c0,0.1,0,0.1,0,0c0.1,0,0.1,0,0.1,0.2
                c0,0.1,0.1,0.3,0.1,0.3c0.1,0.1,0.1,0.1,0-0.1c-0.1-0.2-0.1-0.2,0,0c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.1,0,0.1
                c0.1,0,0.1,0,0.1,0c0,0.1,0.1,0.3,0.2,0.6c0.1,0.2,0.3,0.5,0.3,0.7c0,0.1,0.1,0.2,0.1,0.2c0.1,0,0,0.4-0.2,0.6
                c-0.2,0.2-0.2,0.2,0,0.1c0.1,0,0.2,0,0.2,0.1c0,0.1,0.1,0.2,0.2,0.2c0.2,0.1,0.2,0.1,0-0.2c-0.2-0.2-0.2-0.3-0.1-0.5l0.1-0.2
                l0,0.2c0,0.3,0,0.3,0.1,0.4c0.1,0.1,0.6,1.8,0.5,1.9c0,0,0,0.2,0.1,0.4c0.1,0.2,0.1,0.5,0.1,0.6c0,0.3,0,0.3-0.1,0.1
                c-0.1-0.1-0.1,0-0.1,0.5c0,0.5,0,0.7-0.1,0.6c-0.1,0-0.1,0.1,0,0.4c0.1,0.7,0.3,0.8,0.2,0.2c0-0.3,0-0.5,0-0.5
                c0,0,0.1-0.1,0-0.3c0-0.2,0-0.4,0-0.5c0.1-0.1,0.1,0.1,0.1,0.8c0,1.4,0,1.5-0.1,1.7c0,0.1-0.1,0.4-0.1,0.5
                c0,0.2,0,0.3-0.1,0.3c0,0-0.1,0.1-0.1,0.2c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.3-0.1,0.6-0.4,1.4
                c-0.1,0.2-0.2,0.4-0.2,0.5c0,0-0.1,0.2-0.2,0.5c-0.1,0.2-0.2,0.4-0.2,0.5c0,0.1,0,0.1-0.1,0c-0.1,0-0.1,0,0,0
                c0.1,0.1-0.1,0.5-0.2,0.4c-0.1-0.1-0.1-0.2,0-0.2c0,0,0.1,0,0.1-0.1c0-0.2,0-0.2-0.1,0c-0.1,0.1,0,0.2,0,0.3
                c0.1,0.1,0.1,0.2,0,0.3c-0.1,0.1-0.1,0.1-0.2,0c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.2-0.2,0.3
                c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.1,0,0,0.1c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.1,0-0.1,0.1c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.1,0-0.1,0.1
                c0.1,0.1-0.2,0.4-0.3,0.3c0,0-0.1,0,0,0.1c0.1,0.2-0.3,0.5-0.6,0.5c-0.1,0-0.2,0-0.2,0.1c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1-0.1
                c0-0.1,0-0.1-0.1,0c-0.1,0.2,0,0.3,0.2,0.1c0.3-0.3,0.3-0.2,0.2,0c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0-0.2,0.1-0.3,0.3
                c-0.1,0.2-0.3,0.3-0.3,0.2c0,0-0.1,0-0.1,0.1c0,0.1,0,0.1,0.1,0c0.3-0.2-0.3,0.4-0.9,0.8C93.2,36.9,93,37,92.8,37
                c-0.1,0-0.2,0-0.1,0c0.2,0.1,0.1,0.2,0,0.2c-0.1,0-0.1,0-0.2,0.1c0,0.1-1.1,0.2-1.1,0.1c0,0-0.2,0-0.4,0c-0.3,0.1-0.4,0-0.3,0
                c0.1-0.1,0-0.1-0.3-0.1c-0.2,0-0.6,0-0.8,0c-0.2,0-0.6,0-0.9-0.1c-0.3,0-0.8-0.1-1-0.1C87.5,37,87.3,37,87.3,37
                c-0.1,0-1-0.1-1.5-0.2c-0.1,0-0.4-0.1-0.6-0.1c-0.2,0-0.5-0.1-0.5-0.1c0,0-0.2-0.1-0.3-0.1c-0.3,0-2.1-0.4-2.4-0.5
                c-0.1-0.1-0.5-0.1-0.7-0.2c-0.4-0.1-1.5-0.4-2.2-0.6C79,35.1,79,35.1,79,35c0-0.1,0-0.1-0.1,0c-0.1,0.1-0.3,0-1.1-0.3
                c-0.2-0.1-0.6-0.2-0.8-0.3c-0.2-0.1-0.8-0.3-1.4-0.6c-0.9-0.4-1.1-0.5-0.9-0.6c0.1-0.1,0.1-0.1-0.1,0c-0.1,0-0.2,0-0.3,0
                c0-0.1,0-0.1-0.1,0c0,0-0.3,0-0.5-0.2c-0.6-0.3-0.8-0.4-0.7-0.5c0,0,0,0,0,0c-0.1,0.1-0.5-0.1-0.7-0.3c0-0.1-0.3-0.2-0.5-0.3
                c-0.2-0.1-0.5-0.2-0.6-0.3c-0.1-0.1-0.4-0.2-0.5-0.3c-0.2-0.1-0.5-0.3-0.8-0.5c-0.4-0.3-0.5-0.4-0.4-0.5
                c0.1-0.1,0.1-0.1-0.1,0c-0.1,0-0.3,0-0.6-0.3c-0.2-0.2-0.4-0.3-0.5-0.3c0,0-0.1,0,0-0.1c0,0,0-0.1-0.1-0.1c-0.1,0-0.3,0-0.4,0
                c-0.7-0.1-1.6,1.2-1.7,2.5c0,0.1,0,0.4-0.1,0.5c0,0.2,0,0.9,0,1.6c0,0.8,0,1.2,0,1.1c-0.1-0.1-0.1-0.1-0.1,0
                c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.3,0.1,0.5c0,0.3,0,0.5,0.1,0.7c0.2,0.3,0.2,0.9,0,0.9c-0.1,0-0.1-0.1-0.2-0.4l0-0.5
                l0,0.5c0,0.4,0,0.5,0.2,0.6c0.1,0.1,0.1,0.1,0,0.1c-0.1,0-0.2,0-0.1,0.1c0,0.1,0.1,0.1,0.1,0c0.1-0.2,0.2,0,0.3,0.5
                c0,0.3,0.1,0.6,0.1,0.7c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.1,0,0,0.1c0.1,0.1,0.2,0.4,0.2,1.1c0.1,0.5,0.1,1,0.1,1
                c0,0,0.1,0.3,0.1,0.4c0,0.2,0.1,0.6,0.2,0.9c0.1,0.3,0.1,0.6,0.1,0.7c0,0.1,0,0.1,0,0.1c0.1,0,0.1,0.1,0.2,0.4
                c0,0.2,0.1,0.5,0.2,0.7c0.1,0.4,0.3,1.2,0.3,1.5c0,0.1,0,0.2,0.1,0.1c0.1-0.1,1.7-0.2,1.8-0.1c0,0,0.2,0.1,0.3,0
                c0.3-0.1,1.4,0.1,1.4,0.1c0,0,0.1,0.1,0.2,0.1c0.1,0,0.3,0,0.3,0.1c0,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.2,0.1
                c0,0,0,0.1,0.1,0c0,0,0.1,0,0.1,0c0,0.1-0.3,0.2-0.8,0.4c-0.9,0.3-3.1,0.4-3.7,0.2c-0.2-0.1-0.5-0.1-0.6-0.1
                c-0.2,0-0.4-0.2-0.4-0.6c0-0.1,0-0.2-0.1-0.2c0,0-0.2-0.3-0.3-0.7c-0.2-0.6-0.3-0.9-0.2-1c0.1-0.3,0.1-0.5-0.1-0.2l-0.1,0.3
                l0-0.3c0-0.2-0.1-0.4-0.1-0.5c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.1,0-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1-0.1-0.3-0.1-0.5
                c0-0.1-0.1-0.4-0.1-0.5c0-0.2,0-0.3-0.1-0.3c-0.1,0-0.1,0-0.1-0.2c0-0.2,0-0.3,0-0.2c0,0-0.1-0.1-0.1-0.2
                c0-0.1-0.1-0.6-0.2-0.9c-0.1-0.3-0.2-0.8-0.1-0.9c0-0.2,0-0.3,0-0.1c-0.1,0.1-0.1,0.1-0.1-0.2c-0.1-0.4,0-0.6,0.1-0.4
                c0.1,0.1,0.1,0.1,0,0c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.4-1-0.4-1.7c0-0.4-0.1-0.7-0.1-0.7c0,0-0.1-0.7-0.1-1.6
                c-0.1-1.1-0.1-1.6,0-1.6c0.1,0,0.1-0.2,0-0.7c-0.1-0.5-0.1-0.7,0-0.6c0,0.1,0.1,0.1,0.2,0.1c0,0,0-0.1-0.1-0.3
                c-0.2-0.2-0.5-1-0.5-1.3c0-0.1,0-0.1,0-0.1c0,0-0.2-0.1-0.4-0.2c-0.6-0.5-1.6-0.4-2.5,0.2c-0.9,0.6-1.5,0.9-1.7,0.9
                c-0.1,0-0.1,0,0,0c0.2,0.1,0,0.2-0.3,0.2c-0.1,0-0.2,0-0.1,0c0.1,0.1-0.8,0.6-1.9,1.1c-0.3,0.1-0.7,0.3-0.8,0.4
                C56.3,33,56.1,33.1,56,33c-0.1,0-0.1,0-0.1,0c0,0-0.1,0.1-0.3,0.2c-0.2,0.1-0.7,0.2-1,0.4c-1.8,0.7-3.9,1.2-6,1.5
                c-2.2,0.3-3.8,0.3-3.8,0.1c0,0,0-0.1,0.1,0c0.1,0,0.1,0,0.1-0.1c0-0.1,0-0.2,0-0.3c0-0.2,0-0.2,0.1,0c0.1,0.1,0.1,0.1,0.1,0
                c0-0.3,0-0.5,0.1-0.4c0.1,0,0.1,0,0,0c0-0.1,0-0.2,0.1-0.4c0.1-0.1,0.1-0.3,0.1-0.3c0-0.2,0-0.2,0.1,0c0,0.1,0.1,0.2,0.1,0.3
                c0,0.1,0,0.2,0.1,0.2c0.1,0.1-0.1-0.5-0.2-0.7c-0.1-0.1-0.1-0.2,0-0.5c0.1-0.2,0.1-0.4,0.1-0.4c0,0,0-0.1,0-0.2
                c0.1-0.3,0.2-1.1,0.2-1.3c-0.2-1.9-0.2-2.4-0.5-2.9c-0.1-0.2-0.2-0.4-0.1-0.5c0-0.1,0-0.1,0-0.1c-0.1,0.1-0.5-0.5-0.4-0.7
                c0,0,0-0.1-0.1,0c-0.1,0-0.6-0.5-0.6-0.6c0,0,0-0.1-0.1-0.1c0,0-0.1,0,0,0c0.1,0.2-0.1,0.1-0.2,0c-0.1-0.1-0.1-0.1,0-0.1
                c0.1,0,0.1,0,0.1,0c0-0.1-0.1-0.1-0.1-0.1c-0.1,0.1-0.4-0.1-0.4-0.3c0-0.1,0-0.1-0.1,0c-0.1,0.1-0.3,0.2-0.2,0
                c0,0,0-0.1-0.2-0.1c-0.1,0-0.2,0-0.2-0.1c0-0.1,0-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0c0-0.1-0.3-0.1-0.8,0c-0.4,0-0.9,0-1,0
                c-0.1,0-0.2,0-0.2,0c0,0-0.2,0.1-0.4,0.2c-0.3,0-0.6,0.2-0.7,0.2c-0.1,0.1-0.2,0.1-0.2,0.1c0,0-0.1,0-0.3,0.1
                c-0.1,0.1-0.3,0.1-0.4,0.1c-0.1,0-0.1,0,0,0c0,0.1,0,0.1-0.1,0.1c-0.4,0-0.8,0.2-0.7,0.3c0,0-0.1,0.1-0.3,0.3
                c-0.3,0.2-0.5,0.2-0.8,0.2c-0.3,0-0.5,0-0.6,0.1c-0.2,0.1-0.2,0.1,0,0.1c0.3-0.1,0.8-0.2,0.8-0.1c0,0-0.1,0.1-0.2,0.1
                c-0.1,0-0.2,0-0.1,0.1c0,0.1-0.8,0.7-0.9,0.6c0,0-0.1,0-0.2,0.1c-0.1,0.1-0.3,0.3-0.5,0.4c-0.4,0.3-0.6,0.5-0.9,0.8
                c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.1,0c0,0.1-1.1,1.4-1.2,1.4c0,0-0.1,0-0.1,0.1c0,0.1,0,0.1,0.1,0.1
                c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.2,0.1-0.2,0.3c0,0.1-0.2,0.3-0.3,0.5c-0.1,0.1-0.2,0.2-0.1,0.1
                c0-0.1,0,0-0.1,0.1c-0.1,0.1-0.1,0.3-0.1,0.3c0,0.1-0.3,0.9-0.5,1.2c-0.1,0.2-0.1,0.6,0.1,0.8c0.2,0.2,0.3,0.2,0.6,0.1
                c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.1,0.2-0.1c0,0,0.1,0,0.1-0.1c0-0.1,0-0.1-0.1,0c-0.1,0-0.1,0-0.1,0
                c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0-0.1c0-0.1,0-0.1,0-0.1c0.1,0,0.1,0,0.1-0.1c0-0.1,0.6-1.3,0.8-1.4
                c0.1,0,0.2-0.2,0.2-0.4c0.2-0.4,0.7-1.1,0.8-1.1c0.1,0,0.1-0.1,0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0,0,0.2-0.1,0.4-0.3
                c0.3-0.3,0.4-0.3,0.5-0.2c0.1,0.1,0.1,0.1,0.2,0c0-0.1,0-0.2,0-0.2c-0.1-0.1,0.4-0.5,0.6-0.5c0.1,0,0.1,0,0.1-0.1
                c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.2-0.1c0-0.1,0.4-0.4,0.8-0.6c0.2-0.1,0.3-0.1,0.5,0c0.3,0.1,0.4,0.1,0.3,0
                C38.6,28,38.5,28,38.5,28c0,0-0.1,0-0.1,0c0-0.1,0-0.1,0,0c0,0,0.1,0,0.1,0c0-0.1,0.1-0.1,0.3-0.1c0.1,0,0.2-0.1,0.2-0.1
                c0-0.1,0.4-0.3,0.7-0.3c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.2,0,0.3-0.1,0.3-0.1c0.1-0.1,1.1-0.1,1.1,0
                c0,0,0.1,0,0.2,0c0.1,0,0.1,0,0.1,0c0,0.1,0.2,0.2,0.4,0.2c0.1,0,0.4,0.1,0.3,0.2c0,0,0,0.1,0.1,0.1c0.2,0,0.3,0.3,0.2,0.4
                c-0.1,0.1-0.1,0.1,0,0c0.1-0.1,0.2-0.1,0.3,0.2c0.2,0.4,0.4,0.9,0.4,1c0,0,0,0.1,0.1,0.1c0.1,0,0.2,1.7,0.2,2.4
                c-0.1,0.8-0.3,1.6-0.4,1.5c-0.1-0.1-0.1,0,0,0.1c0,0.1,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.5-0.2,0.5c0,0.2-0.3,0.8-0.4,0.7
                c0,0,0,0,0,0c0.1,0.1-0.3,1-0.4,1.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1,0
                c0,0.1,0,0.1,0.1,0.2c0,0-0.1,0.4-0.2,0.8c-0.2,0.4-0.4,0.7-0.4,0.7c-0.1,0-0.1,0,0,0.1c0,0.1,0,0.3-0.2,0.5
                c-0.1,0.2-0.3,0.5-0.3,0.6c-0.1,0.1-0.1,0.2-0.2,0.2c0,0-0.1,0.1-0.1,0.1c0,0.2-0.1,0.3-0.3,0.2c-0.1-0.1-0.3-0.1-0.3,0
                c0,0,0,0.1,0,0.1c0,0,0.1,0,0.1,0c0.1-0.1,0.1-0.1,0.2,0c0.1,0.1,0.1,0.1,0,0.3c-0.1,0.1-0.2,0.1-0.2,0.2c0,0-0.1,0.2-0.2,0.3
                c-0.1,0.2-0.2,0.3-0.3,0.2c-0.1-0.1-0.1-0.1,0,0c0,0.1,0,0.3-0.1,0.4c-0.8,1.2-1,1.4-1.3,1.4c-0.2,0-0.2,0-0.2,0.1
                c0,0.1,0.1,0.1,0.2,0c0.1-0.1,0.1-0.1,0.1,0c0,0.2-0.2,0.5-0.3,0.4c-0.1,0-0.1,0,0,0c0.1,0.1-0.1,0.3-0.8,1.1
                c-0.2,0.2-0.3,0.4-0.3,0.5c0,0-0.1,0.2-0.2,0.2c-0.1,0.1-0.3,0.5-0.5,0.8c-0.3,0.6-0.5,0.8-0.5,0.6c0-0.1,0-0.1-0.1,0
                c0,0,0,0.1,0.1,0.2c0.1,0.1,0.1,0.2,0,0.5c-0.1,0.3-0.1,0.4,0,0.5c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0,0.3,0,0.4,0.1
                c0.1,0,0.2,0.1,0.3,0.1c0.3,0,1.6,0.3,1.9,0.4c0.2,0.1,0.4,0.2,0.3,0.2c0,0,0,0.1,0,0c0.1,0,0.2,0,0.3,0
                c0.2,0.1,0.2,0.1,0.1,0.2c-0.1,0.1-0.1,0.1,0.1,0.1c0.1,0,0.2,0.1,0.3,0.2c0.1,0.2,0.1,0.2-0.1,0.2c-0.1,0-0.7,0.1-1.3,0.1
                c-1,0.1-1,0.1-1.1-0.1c0-0.1,0-0.1-0.1-0.1c0,0.1-0.1,0.1-0.4,0c-0.4-0.1-1.3-0.6-1.3-0.7c0-0.1,0-0.1-0.1,0
                c-0.1,0.1-0.8-0.8-0.9-1.1c0-0.1-0.1-0.2-0.1-0.2c0,0-0.1-0.1-0.1-0.3c0-0.1,0-0.3,0.1-0.3c0.1,0,0.1-0.1,0.1-0.1
                c0-0.1,0.2-0.6,0.3-0.6c0,0,0.1,0,0.1,0.1c0,0.1,0.1,0,0.1-0.2c0.1-0.2,0.1-0.2,0-0.1c-0.1,0.1-0.1,0.1-0.1,0.1
                c0-0.1,0.7-1.3,0.9-1.6c0.1-0.1,0.1-0.2,0.1-0.2c0-0.1,0.7-1.2,0.8-1.1c0,0,0.1,0,0.1-0.1c0-0.1,0-0.1,0,0c-0.1,0-0.1,0-0.1,0
                c0-0.1,0.3-0.7,0.4-0.8c0,0,0.1-0.2,0.2-0.5c0.1-0.4,0.2-0.4,0.3-0.3c0.1,0.1,0.1,0.1,0,0c-0.1-0.2,0.8-2.2,0.9-2.1
                c0,0,0.1,0,0-0.1c0-0.1,0-0.3,0.1-0.6c0.3-0.7,0.5-1.1,0.4-1.3c0-0.1,0-0.1,0.1-0.1c0.1,0.1,0.1,0,0.1-0.1c0-0.2,0.1-1,0.3-2
                c0-0.1,0-0.3-0.1-0.4c-0.4-0.7-0.5-0.8-0.9-1c-0.2-0.1-0.5-0.2-0.6-0.2c-0.1,0-0.3,0-0.5,0.1c-0.2,0.1-0.5,0.2-0.7,0.2
                c-0.2,0-0.3,0.1-0.3,0.1c0,0,0,0.1-0.1,0.1c-0.1,0-0.6,0.3-0.7,0.5c0,0-0.2,0.2-0.3,0.3c-0.2,0.1-0.4,0.3-0.4,0.3
                c-0.3,0.3-1,0.8-1.1,0.7c0,0-0.1,0-0.1,0.1c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.1,0-0.1,0c0,0-0.3,0.3-0.7,0.5
                c-0.6,0.3-0.8,0.4-1,0.4c-0.2,0-0.3,0-0.1,0c0.2,0.1,0.2,0.1-0.1,0.2c-0.1,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.5,0.1-0.5,0.2
                c0.1,0.1-1,0.3-1.2,0.3c-0.2,0-0.2,0-0.1,0c0.1,0.1,0.1,0.1-0.2,0.1c-0.2,0-0.5,0-0.6,0c-0.1,0-0.2,0-0.2,0.1
                c0.1,0.1-0.6,0.2-3.7,0.5c-2.3,0.2-2.6,0.3-3.2,0.4c-0.8,0.2-1.8,0.5-1.9,0.6c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.2,0-0.1,0
                c0.1,0.1,0.1,0.1-0.1,0.2c-0.1,0-0.3,0.1-0.3,0.1c0,0-0.1,0,0,0c0,0-0.1,0.2-0.2,0.3c-0.5,0.4-0.8,0.6-0.9,0.8
                c-0.1,0.1-0.1,0.1-0.2,0c-0.1-0.1-0.1-0.1,0,0c0.1,0.2-1.2,1.6-1.5,1.6c-0.1,0-0.1,0,0,0c0.1,0,0.2,0.1,0.2,0.1
                c0,0-0.2,0.3-0.4,0.6c-0.2,0.3-0.4,0.6-0.5,0.7c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.1,0,0-0.1c0-0.1,0-0.1,0,0
                c-0.1,0-0.1,0.1,0,0.1c0,0.1-0.2,0.5-0.3,0.6c0,0-0.2,0.3-0.3,0.5c-0.2,0.3-0.3,0.5-0.3,0.4c0,0-0.1,0-0.1,0.1
                c0,0.1,0,0.2-0.1,0.2c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.1,0,0,0.1c0,0.1,0,0.1,0,0.1c-0.1,0-0.1,0.1-0.2,0.3
                c0,0.2-0.1,0.3-0.2,0.3c-0.1,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.3-0.3,0.6c-0.1,0.2-0.3,0.6-0.4,0.8c-0.1,0.3-0.3,0.5-0.2,0.2
                c0-0.1,0-0.1,0-0.1c0,0-0.1,0.2-0.1,0.3c0,0.1,0,0.3-0.1,0.4c-0.1,0.2-0.3,0.5-0.2,0.6c0,0,0,0.1-0.1,0c0,0-0.1,0.1-0.2,0.3
                c-0.1,0.5-0.6,1.5-0.7,1.4c0,0,0,0,0,0c0.1,0.1-0.1,0.5-0.2,0.4c-0.1,0-0.1,0,0,0.1c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.1,0-0.1,0.1
                c0.1,0.1-0.1,0.6-0.2,0.7c0,0-0.1,0.1-0.1,0.2c0,0.1-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.5-0.2,0.7c0,0.2-0.1,0.4-0.1,0.4
                c-0.1,0.1,0,2.2,0.1,2.5c0,0.1,0.1,0.4,0,0.5c0,0.1,0,0.2,0,0.2c0.1-0.1,0.4,0.7,0.3,0.8c0,0,0,0.1,0,0.1
                c0.1,0,0.1,0.1,0.1,0.3c0,0.2,0.1,0.3,0.1,0.2c0,0,0.1,0,0.1,0.1c0,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0.1,0.3,0.1,0.5
                c-0.1,0.2-0.1,0.3,0.1,0.4c0.3,0.2,0.3,0.1,0.1-0.1c-0.1-0.1-0.2-0.2-0.1-0.3c0-0.1,0.2-0.1,0.2,0c0,0,0.1,0.2,0.2,0.4
                c0.1,0.2,0.2,0.4,0.2,0.4c0,0,0,0.1,0.1,0.1c0.1,0.1,0.1,0.1,0,0.3c-0.1,0.2-0.1,0.2,0,0.2c0.1,0,0.1,0,0.1-0.1
                c0,0,0-0.1,0-0.1c0,0,0.2,0.1,0.2,0.2c0.1,0.1,0.3,0.3,0.4,0.5c0.1,0.1,0.2,0.2,0.1,0.2c-0.1-0.1-0.1-0.1,0,0.1
                c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0.1c0.1,0,0.1,0,0.1,0.1s-0.1,0.1-0.1,0.1
                c-0.1,0-0.1,0.1-0.1,0.2c0.1,0.3,0.1,0.3,0.1,0.1c0-0.3,0.2-0.3,0.4-0.1c0.1,0.1,0.5,0.4,0.7,0.6c0.5,0.4,0.5,0.4,0.3,0.6
                c-0.2,0.2-0.2,0.2,0.1,0c0.2-0.2,0.2-0.2,0.2,0c0,0.1,0,0.1,0,0.1c0.1-0.1,0.2,0,0.5,0.1c0.2,0.1,0.3,0.2,0.3,0.3
                c0,0-0.1,0-0.2-0.1c-0.3-0.2-0.4-0.2-0.4-0.1c0,0.1,0.1,0.1,0.1,0.1c0.2-0.1,0.1,0.1,0,0.2c-0.1,0.1-0.1,0.1,0,0.1
                c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0,0,0c-0.1-0.1-0.1-0.1,0.1-0.1c0.1,0,0.3,0.1,0.5,0.2c0.1,0.1,0.3,0.2,0.4,0.2
                c0.1,0,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0,0c0.1,0,0.3,0,0.4,0.2c0.8,0.6,2.4,1.1,3.2,1.1c0.3,0,0.3,0,0.2,0.1
                c-0.2,0.1-0.2,0.1,0,0.1c0.1,0,0.3,0,0.4,0c0.1,0,0.1,0,0,0c-0.1-0.1,0.8-0.4,1.5-0.5c0,0,0.1,0,0-0.1c0,0,0.2-0.3,0.4-0.5
                c0.4-0.4,0.4-0.4,0.4-0.2c0,0.2,0,0.2,0.1,0.2c0.1,0,0.1-0.2,0-0.3c0-0.1,0-0.4,0.2-0.8c0.4-0.8,0.7-2.2,0.8-2.9
                c0-0.2,0-0.7,0.1-1.1c0.1-0.8-0.1-4-0.2-4.5c0-0.2,0-0.3,0-0.3c0.1,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.2,0.1
                c0.1,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.2,0.2,0.2c-0.1,0-0.1,0-0.1,0.1c0,0.1,0,0.2,0,0.2c0,0,0.1,0,0.2-0.1
                c0.1-0.1,0.2-0.1,0.4,0c0.1,0.1,0.2,0.2,0.2,0.2c0,0,0,0,0.1,0c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0.2,0.3,0.5,0.5
                c0.3,0.3,0.4,0.3,0.3,0.4c0,0.1-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0c0,0.1,0.2,0.1,0.4-0.1c0.1,0,0.2,0.1,0.4,0.3
                c0.2,0.2,0.3,0.4,0.2,0.3c-0.1,0-0.1,0-0.1,0c0,0.1,0.1,0.1,0.1,0.1c0.2,0,0.3,0.2,0.1,0.4c-0.1,0.2-0.2,0.2-0.3,0.1
                c0-0.1-0.1-0.1-0.1,0c0,0.1,0,0.1,0.2,0.1c0.1,0,0.2,0,0.2-0.1c0-0.1,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0.1
                c0.1-0.1,0.3,0.5,0.3,0.6c0,0,0,0.1,0,0c0.1-0.1,0.7,1.2,0.7,1.6c0,0.2,0,0.3,0.1,0.2c0.1-0.1,0.4,1,0.5,1.6
                c0.1,0.6,0.1,0.7,0.2,0.8c0.1,0.1,0.2,2.2,0.1,2.4c0,0.1-0.1,0.3-0.1,0.5c0,0.1-0.1,0.4-0.1,0.5c0,0.1-0.1,0.3-0.1,0.4
                c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.2,0.4c-0.1,0.2-0.2,0.2-0.1,0.2c0.1,0,0,0.1-0.1,0.3
                c-0.1,0.2-0.3,0.4-0.3,0.3c0,0-0.1,0-0.1,0c0,0.2,0,0.4-0.6,1c-0.4,0.4-0.7,0.7-0.8,0.7c-0.1,0-0.1,0-0.1,0.1
                c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.1,0-0.1,0.1c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.3,0.2-0.4,0.1
                c-0.1,0-0.2,0-0.1,0c0.1,0,0.1,0.1,0.1,0.1c0,0.1-0.1,0.1-0.2,0c-0.1-0.1-0.1-0.1-0.1,0c0,0.1,0,0.1-0.1,0.1
                c-0.1,0-0.1,0-0.1,0c0.1,0.1-0.4,0.3-0.5,0.3c-0.1,0-0.1,0-0.1,0c0,0.1,0,0.1,0,0.1c-0.2,0-0.2,0-0.3,0.1
                c-0.1,0.1-1.3,0.5-1.7,0.6c-0.2,0-0.4,0.1-0.6,0.1c-0.1,0-0.4,0.1-0.6,0.1c-0.2,0-0.4,0.1-0.5,0.1
                C21.4,74.4,21.3,74.4,21.3,74.4z M21.7,74.1c0,0-0.3-0.1-0.5-0.1c-0.3-0.1-0.4-0.1-0.4,0c0,0,0.2,0.1,0.3,0.1
                c0.1,0-0.2,0-0.7,0.1c-0.5,0-0.9,0.1-1,0.1c-0.1,0,0.4,0.1,1.1,0C21.2,74.2,21.7,74.2,21.7,74.1z M19.1,74.1
                C19,74,18.9,74,18.8,74c-0.1,0-0.1,0,0,0.1C18.9,74.1,19.1,74.1,19.1,74.1C19.2,74.1,19.2,74.1,19.1,74.1z M17.1,73.7
                c-0.1,0-0.3,0-0.3,0.1C16.7,73.8,16.8,73.9,17.1,73.7C17.3,73.8,17.3,73.8,17.1,73.7z M23.9,73.5c0.1-0.1,0.1-0.1-0.1-0.1
                c-0.1,0-0.3,0.1-0.3,0.1C23.5,73.6,23.8,73.6,23.9,73.5z M25.1,73.2c0,0-0.1-0.1-0.2-0.2c-0.2-0.1-0.2-0.1-0.1,0
                c0.1,0.1,0.1,0.1,0,0.2c-0.1,0,0,0,0.1,0C25,73.3,25.1,73.3,25.1,73.2z M22.5,73.2c-0.2-0.1-0.3-0.1-0.3,0
                C22.2,73.3,22.3,73.3,22.5,73.2C22.6,73.3,22.6,73.3,22.5,73.2z M14.2,73L14,72.9L14.2,73c0.1-0.1,0.3,0,0.4,0s0.1,0,0.1-0.1
                c0-0.1,0-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.2-0.1-0.4-0.1-0.6,0c-0.2,0.1-0.2,0.1,0.1,0.2C14.4,73.2,14.5,73.2,14.2,73z
                 M26.9,72.3c0,0,0,0-0.1-0.1c-0.1,0-0.1,0-0.1,0.1c0,0.1,0,0.1-0.1,0c-0.1,0-0.1,0-0.1,0.1c0,0.1,0.1,0.1,0.2,0
                C26.8,72.4,26.9,72.3,26.9,72.3z M27,71.9C27,71.8,27.1,71.8,27,71.9c0.1,0,0.2,0,0.2-0.1c0-0.1,0-0.1,0.1,0c0.1,0,0.1,0,0,0
                c0,0,0-0.1,0.1-0.2c0.1-0.1,0.1-0.1,0,0c-0.1,0.1-0.2,0.1-0.3,0c0,0,0,0,0,0c0,0.1,0,0.1-0.1,0.1c-0.2,0-0.3,0.3-0.1,0.3
                C27,72,27.1,71.9,27,71.9z M28,71.1c-0.1-0.1,0.1-0.3,0.2-0.3c0.1,0,0,0.1,0,0.2c-0.1,0.1-0.1,0.1,0,0
                c0.1-0.1,0.1-0.2,0.1-0.2c-0.1-0.1-0.1,0-0.3,0.1c-0.1,0.1-0.2,0.1-0.2,0.1c0,0,0,0.1,0,0.2c0,0.2,0,0.2,0.1,0.1
                C28,71.2,28.1,71.1,28,71.1z M11.8,71.7C11.6,71.6,11.6,71.6,11.8,71.7c0.1,0,0.2-0.1,0.1-0.1C11.7,71.4,11.5,71.6,11.8,71.7
                c0,0.2,0.1,0.2,0.1,0.2C11.9,71.9,11.9,71.8,11.8,71.7z M28.4,70.4C28.4,70.3,28.4,70.3,28.4,70.4c-0.1-0.1-0.2,0-0.2,0.1
                c0,0.1,0,0.1,0.1,0.1C28.3,70.6,28.4,70.5,28.4,70.4z M28.9,70.1c0-0.1-0.1-0.1-0.2-0.1c-0.2,0-0.2,0,0,0.1
                c0.1,0.1,0.1,0.1,0,0.3c-0.1,0.1-0.1,0.2,0,0C28.8,70.3,28.9,70.2,28.9,70.1z M29.3,69.4c0-0.2,0-0.2-0.1-0.1
                c0,0.1-0.1,0.2,0,0.3C29.3,69.7,29.3,69.7,29.3,69.4z M29.7,68.6c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0,0,0.1c0,0,0,0.2-0.1,0.3
                c-0.1,0.1-0.1,0.2-0.1,0.1c0-0.1,0-0.1-0.1-0.1c0,0,0,0.1,0,0.2C29.4,69.2,29.7,68.8,29.7,68.6z M9.2,69.3
                c0-0.1,0-0.2-0.1-0.2c0,0-0.1,0,0,0.1c0.1,0.1,0,0.1-0.1,0.1c-0.1-0.1-0.1,0,0,0.1C9.2,69.5,9.2,69.5,9.2,69.3z M7.7,66.2
                c0,0-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.2,0c0,0.1,0,0.1,0,0.1c0,0,0.1,0,0.2,0.1C7.6,66.3,7.7,66.3,7.7,66.2z M7.2,65
                c-0.1-0.1-0.1,0-0.1,0.1c0,0.3,0.1,0.4,0.2,0.2C7.3,65.2,7.2,65.1,7.2,65z M29,63.2c0-0.2,0-0.1,0,0.2s0,0.4,0,0.3
                C29,63.6,29,63.3,29,63.2z M24.4,63.6C24.4,63.4,24.4,63.4,24.4,63.6c-0.2,0.2-0.2,0.2-0.1,0.2C24.4,63.8,24.4,63.7,24.4,63.6
                z M14,63.5c0,0,0-0.1,0-0.2c0.1-0.1,0.1-0.1-0.1,0c-0.2,0.1-0.2,0.1-0.3,0c-0.1-0.1-0.2-0.1-0.2-0.1s0.1,0.1,0.2,0.2
                C13.9,63.6,14.1,63.6,14,63.5z M29.8,62.7c0,0,0-0.1,0-0.2c0-0.2,0-0.2-0.1,0c0,0.1-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0
                c0,0.1,0.1,0.1,0.1,0.1C29.7,62.7,29.8,62.7,29.8,62.7z M6.7,63.4c0-0.1,0-0.2,0-0.2c0,0,0-0.1,0-0.2l-0.1-0.3l0,0.3
                c0,0.2-0.1,0.2-0.1,0.1c0-0.1,0-0.1-0.1-0.1c0,0.1,0.2,0.6,0.2,0.6C6.7,63.6,6.7,63.5,6.7,63.4z M13,62.5
                c-0.2-0.1-0.3-0.1-0.2,0c0,0.1,0.1,0.1,0.2,0.1C13.1,62.6,13.1,62.6,13,62.5z M12.7,62C12.8,62,12.8,62,12.7,62
                c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1-0.1c0-0.1,0,0,0,0.1c0,0.2,0,0.2,0.1,0.1C12.6,62,12.7,62,12.7,62z M10.7,59.3
                c-0.1-0.3-0.2-0.4-0.2-0.1c0,0.2,0.1,0.3,0.1,0.3C10.8,59.4,10.8,59.4,10.7,59.3z M25,57.9c0-0.1,0,0,0,0.2
                c0,0.2,0,0.2,0.1,0.2C25,58.1,25,58,25,57.9z M10.3,58.1c0-0.1,0-0.2-0.1-0.1c0,0-0.1,0.1-0.1,0.2
                C10.2,58.5,10.3,58.4,10.3,58.1z M10.2,57.3C10.1,57.1,10.1,57.1,10.2,57.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0.1,0
                C10.3,57.5,10.2,57.4,10.2,57.3z M10,54.2L9.9,54L10,54.2c0,0.1,0,0.4,0,0.5l0,0.3l0-0.3C10.1,54.6,10.1,54.4,10,54.2z
                 M10.2,53.4c0-0.1,0-0.1-0.1-0.1c0,0-0.1,0.1-0.1,0.3c0,0.2,0,0.3,0.1,0.1C10.2,53.6,10.2,53.4,10.2,53.4z M11.7,50.4
                c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0,0,0.2c0.1,0.1,0.2,0.2,0.2,0.2S11.7,50.4,11.7,50.4z M71.2,47.5c-0.1,0-0.2,0-0.3,0
                c-0.1,0,0,0,0.2,0C71.2,47.5,71.2,47.5,71.2,47.5z M40.2,48.8c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.2,0-0.3,0c-0.2,0-0.2,0,0,0.1
                C40.2,48.9,40.2,48.9,40.2,48.8z M72.5,47.2C72.6,47.2,72.6,47.2,72.5,47.2c-0.1-0.2-0.4-0.2-0.4,0c0,0.1,0,0.1,0.1,0.1
                C72.3,47.1,72.4,47.1,72.5,47.2z M71.7,47.1C71.9,47,71.9,47,71.7,47.1c-0.2,0-0.3,0-0.3-0.1c0-0.1,0-0.1-0.1,0
                c-0.1,0-0.1,0.1-0.1,0.1C71.3,47.3,71.5,47.2,71.7,47.1z M37.8,48C37.9,48,37.9,48,37.8,48c0,0.1,0.2,0.1,0.4,0.1l0.4,0
                L38.3,48C38.2,48,37.9,47.9,37.8,48c-0.2-0.1-0.2-0.1-0.2-0.1c0,0,0,0.1-0.1,0.1c-0.1,0-0.1,0.1-0.1,0.1c0,0.1,0.1,0.1,0.2,0
                C37.7,48,37.8,48,37.8,48z M36.9,47.9c-0.2-0.1-0.3-0.1-0.1-0.2c0.1-0.1,0.1-0.1,0-0.1c-0.1,0-0.2,0-0.2,0.1
                C36.6,47.9,36.8,48,36.9,47.9C37.2,48,37.1,48,36.9,47.9z M36,46.1c-0.1-0.1-0.2-0.1-0.2,0c0,0,0,0.1-0.1,0
                c-0.1-0.1-0.1,0.3,0,0.4C35.9,46.6,36.1,46.2,36,46.1z M6.7,47.2c0-0.2-0.1-0.4-0.1-0.4c0,0,0,0.2,0,0.3
                c0.1,0.4,0.1,0.4,0.1,0.4C6.7,47.6,6.7,47.4,6.7,47.2z M67.3,44.2c0-0.1,0-0.3-0.1-0.4l-0.1-0.2l0,0.2c-0.1,0.3,0,0.5,0.1,0.5
                C67.3,44.3,67.3,44.3,67.3,44.2z M36.8,45.1c-0.1-0.1-0.2-0.2-0.2-0.1c0,0.1,0.2,0.3,0.3,0.3C36.8,45.2,36.8,45.2,36.8,45.1z
                 M37.5,44c-0.1-0.1-0.4,0-0.3,0.1c0,0,0.1,0.2,0.1,0.2c0.1,0.1,0,0.1,0,0.1c-0.1,0-0.1,0,0,0.1c0,0.1,0.1,0.1,0.2-0.1
                C37.5,44.2,37.6,44.1,37.5,44z M7,45.5C7,45.4,7,45.4,7,45.5c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0,0.1,0.1,0
                C7,45.7,7,45.6,7,45.5z M7.3,45.4c0.1,0.1,0.1,0,0-0.2c0-0.1-0.1-0.1-0.2-0.1C7,45.1,7,45.1,7,45.2c0,0.1,0.1,0.3,0.1,0.4
                l0,0.3l0-0.3C7.2,45.4,7.2,45.3,7.3,45.4z M66.9,42.1c0-0.5-0.2-0.5-0.2-0.1c0,0.2,0,0.4,0,0.4C66.9,42.6,66.9,42.4,66.9,42.1
                z M65.9,41.7c-0.1-0.2-0.1-0.2-0.1-0.1c0,0.1,0,0.2,0,0.1c0.1,0,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0,0.1C66,42,66,41.8,65.9,41.7z
                 M38.3,43.1c0-0.1,0-0.2-0.1-0.2c-0.2,0-0.2,0.2,0,0.3c0.1,0.1,0.1,0.1,0.1,0.1C38.3,43.3,38.3,43.2,38.3,43.1z M7.7,43.7
                c0-0.1-0.1-0.2-0.1-0.2c0,0,0,0.1,0,0.3c0,0.1,0.1,0.2,0.1,0.2C7.7,44,7.7,43.9,7.7,43.7z M66.6,40.6c0-0.2,0-0.4-0.1-0.4
                c0,0,0,0.2,0,0.4s0.1,0.4,0.1,0.4C66.6,41,66.6,40.8,66.6,40.6z M39.4,41.2C39.4,41.2,39.4,41.2,39.4,41.2
                c-0.1,0.2-0.1,0.2-0.2,0.2c-0.1,0,0,0,0,0.1c0.1,0.1,0.1,0.1,0.2,0C39.5,41.4,39.5,41.3,39.4,41.2z M39.9,41.1
                c0-0.2,0-0.2-0.1-0.1c-0.1,0-0.2,0.1-0.3,0c-0.2,0-0.2,0,0,0.1c0.1,0.1,0.2,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0,0.1,0,0.1,0,0.1
                C39.9,41.3,39.9,41.2,39.9,41.1z M9.3,42.2c0-0.2,0-0.2-0.1-0.1c-0.1,0.2-0.1,0.4,0,0.4C9.3,42.5,9.3,42.3,9.3,42.2z M66.3,39
                c0-0.1,0-0.2,0-0.2c0,0-0.1,0.1-0.1,0.2C66.2,39.3,66.2,39.3,66.3,39z M91,37.1c0,0-0.2-0.1-0.3-0.1c-0.3,0-0.3,0-0.1,0.1
                C91,37.2,91,37.2,91,37.1z M93.7,36.3C93.9,36.3,93.9,36.3,93.7,36.3c0-0.1,0-0.2,0-0.2c0.1-0.1,0-0.1-0.3,0
                c-0.1,0.1-0.1,0.1,0,0.1c0.1,0,0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.1,0,0.1C93.4,36.4,93.6,36.3,93.7,36.3z M41.3,38.5
                c0-0.1,0-0.2,0.1-0.2c0.1,0,0.2,0.1,0.2,0.1c0,0.1,0.1,0,0.1-0.1c0.1-0.3,0-0.5-0.2-0.2c-0.1,0.1-0.2,0.2-0.2,0.1
                C41.2,38.3,41.2,38.4,41.3,38.5c-0.1,0.1-0.1,0.2,0,0.2C41.3,38.7,41.3,38.6,41.3,38.5z M9.1,39.4C9.1,39.4,9.1,39.4,9.1,39.4
                C9,39.4,9,39.4,9,39.5c0,0.1,0,0.1,0,0.2c-0.1,0-0.1,0.1-0.1,0.2c0,0.1,0.1,0.1,0.1-0.1C9.1,39.6,9.1,39.5,9.1,39.4z
                 M42.2,36.6c0-0.2-0.2,0.1-0.2,0.3l0,0.3l0.1-0.3C42.2,36.9,42.2,36.7,42.2,36.6z M9.7,38.4c-0.1,0-0.1-0.1-0.1-0.2
                c0-0.1,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0-0.2-0.3-0.1-0.3,0.1c-0.1,0.2,0,0.5,0.2,0.4C9.8,38.5,9.8,38.5,9.7,38.4z M96,34.3
                c-0.1-0.1-0.1-0.1,0-0.2c0.1-0.1,0.1-0.1,0-0.1c-0.1,0-0.2,0.1-0.2,0.1c0,0.2,0.1,0.3,0.2,0.3C96,34.4,96,34.3,96,34.3z
                 M42.9,35.6c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.1,0,0,0c0,0.1,0,0.2,0,0.3l-0.1,0.2l-0.1-0.2c-0.1-0.2-0.1-0.2-0.1,0
                c0,0.1,0,0.2,0.1,0.2C42.7,36,42.9,35.8,42.9,35.6z M10.3,37.1c-0.1-0.3-0.1-0.3-0.2-0.2c0,0.1,0,0.2,0,0.2
                c0,0,0.1,0.1,0.1,0.2C10.4,37.6,10.4,37.4,10.3,37.1z M40.7,34.6c0-0.2,0-0.3-0.1-0.2c-0.1,0.1,0,0.5,0.1,0.5
                C40.7,35,40.7,34.8,40.7,34.6z M43.3,34.2c0-0.2,0.1-0.2,0.2-0.2c0.1,0,0.1,0,0.1-0.2l0-0.2l-0.1,0.2
                C43.3,33.9,43.2,34.1,43.3,34.2c-0.1,0.1-0.1,0.2-0.1,0.2c-0.1,0,0,0,0,0C43.3,34.4,43.3,34.3,43.3,34.2z M45.6,34.1
                c-0.1-0.1-0.1-0.2,0-0.2c0-0.1,0-0.1,0,0c-0.1,0.1,0,0.4,0.1,0.4C45.6,34.3,45.6,34.2,45.6,34.1z M64.8,32.3
                C64.8,32.1,64.8,32.1,64.8,32.3c0,0.2-0.1,0.4-0.1,0.4c0,0-0.1,0.2-0.1,0.4l0,0.4l0.1-0.4C64.8,32.7,64.8,32.4,64.8,32.3z
                 M54.5,33.5c0.1-0.1-0.4,0-0.5,0.1c-0.1,0.1,0,0.1,0.2,0C54.3,33.6,54.5,33.5,54.5,33.5z M66,32.7c0-0.1,0,0,0,0.2
                c0,0.2,0,0.2,0.1,0.2C66,32.9,66,32.8,66,32.7z M32.1,34.5c0-0.1-0.1-0.1-0.2,0c-0.4,0.1-0.4,0.1-0.1,0.1
                C31.9,34.6,32.1,34.6,32.1,34.5z M30.8,34.2c0-0.1,0-0.1-0.1-0.1c0,0-0.1,0.1-0.1,0.1c0,0.1,0.1,0.1,0.1,0.1
                C30.8,34.3,30.8,34.2,30.8,34.2z M11.5,34.7c0-0.2-0.1-0.1-0.2,0.1c-0.1,0.1-0.1,0.2,0.1,0.1C11.5,34.8,11.5,34.7,11.5,34.7z
                 M44,32.6c0-0.3,0-0.5,0-0.4c0,0-0.1,0.1-0.1,0.2c0,0.2,0,0.1-0.1-0.1c-0.1-0.3-0.1-0.3-0.1,0c0,0.1,0,0.3,0.1,0.3
                C43.8,32.7,44,32.7,44,32.6z M12.5,33.3c0.1,0,0.1-0.1,0.1-0.2c0-0.1,0-0.2-0.1-0.2c0,0-0.1,0.1-0.1,0.2
                c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.1,0-0.1,0c0,0,0,0.1-0.1,0.2c0,0.1,0,0.1,0.1,0C12.4,33.4,12.5,33.3,12.5,33.3z M31.3,32.3
                c0.1-0.1,0.1-0.1,0-0.1c-0.1,0-0.2,0.2-0.2,0.4c0,0.1,0,0.1,0.1,0C31.2,32.5,31.2,32.4,31.3,32.3z M43.9,31.6c0,0,0.1,0,0-0.2
                c-0.1-0.2-0.1-0.2-0.1,0c0,0.1-0.1,0.2,0,0.3c0,0.1,0,0.1,0,0C43.8,31.6,43.9,31.5,43.9,31.6z M34.4,31.9
                C34.4,31.8,34.4,31.8,34.4,31.9c-0.1,0-0.1,0-0.1-0.1c0,0,0-0.1,0.1-0.1c0.1,0,0.1-0.1,0.1-0.2c0-0.1,0-0.1-0.1,0
                c-0.1,0.1-0.1,0.1-0.1-0.1c0,0,0-0.1-0.1,0c-0.1,0.1,0,0.3,0.1,0.5C34.3,32,34.3,32,34.4,31.9z M35.2,30.9
                C35.2,30.8,35.2,30.8,35.2,30.9c0.2-0.1,0.2-0.1,0.2-0.2c0-0.1-0.1-0.1-0.3-0.1c-0.2,0-0.2,0-0.2,0.2c0,0.1,0,0.2,0.1,0.1
                C35,30.8,35.1,30.8,35.2,30.9c0,0.1,0,0.2,0,0.2C35.2,31.1,35.2,31,35.2,30.9z M13.4,31.3c0-0.1-0.1-0.1-0.2,0
                c-0.1,0.1-0.1,0.1,0,0.2c0,0.1,0.1,0.1,0.2,0C13.4,31.5,13.4,31.4,13.4,31.3z M35.5,30.3c-0.1-0.1,0-0.2,0.2-0.1
                c0.1,0,0.1,0,0-0.1C35.5,30,35.3,30.1,35.5,30.3c0,0.1,0,0.1,0.1,0.1C35.6,30.4,35.6,30.4,35.5,30.3z M43.7,29.2L43.5,29
                l0,0.2c0,0.1,0.1,0.2,0.1,0.2c0,0,0.1,0,0.1,0.2c0,0.1,0,0.2,0.1,0.1C43.8,29.6,43.8,29.4,43.7,29.2z M14.2,30.5
                c0.1-0.2,0.1-0.3,0.1-0.4c-0.1-0.1-0.1-0.1-0.1,0.1c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.1,0.2
                C14,30.8,14.1,30.6,14.2,30.5z M36.7,29.3c0,0,0-0.1-0.1-0.1c0,0-0.1,0,0,0.1c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.2,0-0.2,0.1
                c0,0.1,0,0.1,0.2,0C36.6,29.4,36.7,29.3,36.7,29.3z M33.3,29.6C33.5,29.6,33.5,29.6,33.3,29.6c-0.1-0.2-0.2-0.2-0.3-0.1
                c-0.2,0.2-0.2,0.3,0,0.2C33.1,29.6,33.2,29.6,33.3,29.6z M33.6,29.4c0,0,0-0.1,0-0.2c0.1-0.1,0-0.1-0.2-0.1
                c-0.2,0.1-0.2,0.1-0.1,0.2C33.5,29.4,33.7,29.5,33.6,29.4z M98.4,25.6c0-0.1,0,0,0,0.2s0,0.4,0,0.2
                C98.5,25.9,98.5,25.7,98.4,25.6z M34,28.7c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.2-0.1c0-0.1-0.1-0.1-0.3-0.1
                c-0.2,0-0.3,0-0.2,0.1c0.1,0,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0,0.1C34,28.8,34,28.8,34,28.7z M42.9,27.8c0-0.1-0.1-0.2-0.1-0.2
                c0,0,0,0,0,0c-0.1,0.1-0.1,0.4,0,0.4C43,28,43,27.9,42.9,27.8z M89,25.7c0,0-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.1-0.1,0
                c0,0.1,0,0.1,0.1,0.1c0,0,0.1,0,0,0.1C88.8,25.7,88.8,25.8,89,25.7C88.9,25.8,89,25.7,89,25.7z M85.3,25.6
                c0-0.1-0.2-0.1-0.3-0.2c-0.4,0-0.4,0,0,0.1c0.3,0,0.3,0,0.1,0.1c-0.2,0.1-0.2,0.1,0.1,0.1C85.3,25.8,85.4,25.7,85.3,25.6z
                 M89.3,25.4c0,0,0-0.1,0-0.1c0,0,0.1,0,0.1,0c0,0.1,0,0.1,0.1,0.1c0,0,0.1-0.1,0.1-0.1c0-0.1-0.5-0.1-0.6,0c0,0,0,0,0,0
                C89.1,25.2,89.1,25.3,89.3,25.4C89.2,25.4,89.2,25.5,89.3,25.4C89.3,25.5,89.3,25.4,89.3,25.4z M35.1,27.9
                C35,27.9,35,27.9,35.1,27.9c0.2,0,0.2,0,0.3-0.1c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.2-0.2,0.1-0.3
                c-0.1-0.1-0.3,0-0.3,0.2c0,0.1,0,0.1-0.3,0.2C35.1,27.7,34.9,27.8,35.1,27.9c0,0.1,0,0.1,0,0.1C35.1,28,35.1,28,35.1,27.9z
                 M45.2,27.1c0-0.1,0-0.2-0.1-0.2c0,0-0.1,0.1-0.1,0.2C45.1,27.4,45.2,27.3,45.2,27.1z M84.6,25.3c0-0.1-0.1-0.2-0.1-0.2
                c-0.1,0-0.1,0,0,0.1c0,0.1,0,0.1-0.1,0c-0.1,0-0.1,0-0.1,0.1C84.3,25.5,84.6,25.5,84.6,25.3z M15.3,28.5c-0.1-0.2,0-0.2,0.2,0
                c0.1,0.1,0.1,0.1,0.1,0c0-0.1,0-0.2,0-0.2c0.1,0,0.1,0,0-0.1c-0.1-0.1-0.1-0.1-0.3,0C15.2,28.4,15.2,28.5,15.3,28.5
                C15.3,28.7,15.3,28.7,15.3,28.5z M41.6,27.3c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0,0c-0.1,0-0.1,0.1,0,0.2
                c0,0.1,0,0.1-0.2,0c-0.2,0-0.2,0-0.1,0C41.1,27.4,41.7,27.4,41.6,27.3z M89.9,24.9c0.1,0,0.2,0,0.2,0.1c0,0.1,0.2,0.1,0.3-0.1
                c0.2-0.2,0.2-0.2-0.1-0.2c-0.2,0-0.2,0.1-0.2,0.2c0,0.1,0,0.1-0.1,0C90.1,24.8,90,24.8,89.9,24.9c-0.1,0-0.1,0,0-0.2
                c0.1-0.2,0.1-0.2-0.1,0c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0,0.1,0.1,0C89.7,24.9,89.8,24.9,89.9,24.9z M84,25
                C83.9,24.9,84,24.9,84,25c0.1,0,0.2,0,0.1-0.1c-0.1-0.1-0.6,0-0.6,0.1c0,0,0.1,0,0.2,0c0.1,0,0.2,0,0.1,0c0,0,0,0.1,0.1,0.1
                C84,25.2,84,25.1,84,25z M44.8,26.5C44.7,26.3,44.7,26.3,44.8,26.5c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0,0,0.1,0.1,0
                C44.8,26.6,44.8,26.6,44.8,26.5z M98.9,23.4C98.9,23.3,98.8,23.3,98.9,23.4c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.1,0.1,0
                C98.9,23.6,98.9,23.5,98.9,23.4z M82.9,24.2c0-0.2,0-0.2-0.2-0.1c-0.1,0.1-0.2,0.1-0.1,0.2C82.8,24.5,82.9,24.4,82.9,24.2z
                 M16.4,27.5c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0-0.1c-0.1-0.1-0.1-0.1-0.2,0c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.1,0.1,0,0.1
                C16.3,27.6,16.4,27.6,16.4,27.5z M37.5,26.3c0,0-0.1,0-0.2,0c-0.1,0-0.3,0.1-0.3,0.1C37,26.5,37.4,26.4,37.5,26.3z M82.5,24
                c0-0.1-0.1-0.2-0.1-0.2c0,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.1,0.1c0,0.1,0,0.1,0.1,0.1c0,0,0.1,0,0.2,0.1
                C82.5,24.2,82.6,24.2,82.5,24z M91.6,23.2c0-0.1,0-0.3,0-0.5c0-0.2,0-0.3,0-0.2c-0.1,0.2-0.1,0.2-0.1,0c0-0.1-0.1-0.2-0.1-0.2
                c0,0,0,0.2,0.1,0.3c0.2,0.2,0.2,0.3,0.1,0.3c0,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.1,0,0.1c0.1,0,0.1,0.4,0,0.5c0,0-0.1,0.1-0.1,0.2
                C91.3,23.8,91.6,23.3,91.6,23.2z M81.9,23.6c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0,0C81.7,23.6,81.7,23.8,81.9,23.6
                C81.9,23.7,81.9,23.7,81.9,23.6z M43,25.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.1,0,0.1,0.1C43.1,25.5,43.1,25.5,43,25.3z
                 M39.9,25.5c0,0-0.1,0-0.3-0.1c-0.2,0-0.2,0-0.2,0C39.5,25.5,39.9,25.5,39.9,25.5z M92.2,22.3c-0.1-0.1-0.1,0-0.1,0.2
                c0.1,0.2,0.1,0.2,0.1,0C92.3,22.5,92.3,22.4,92.2,22.3z M81.4,22.7c0,0,0-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0.1
                c0,0.1,0.1,0.1,0.1,0.1C81.3,22.8,81.4,22.8,81.4,22.7z M81.1,22.2C81,22.1,81,22,81,22c0.1-0.1,0.1-0.1-0.1-0.3
                c-0.1-0.1-0.1-0.2-0.1-0.2c0,0.1,0,0.1,0.1,0.2c0,0.1,0.1,0.2,0,0.2c-0.1,0.1-0.1,0.5,0,0.4c0.1,0,0.1,0,0,0.1
                c0,0.1,0,0.1,0,0.1C81.1,22.5,81.1,22.4,81.1,22.2z M18.4,24.7c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0,0,0.1
                C18.3,24.9,18.5,24.9,18.4,24.7z M18.9,24.1C19,24,19,24,18.9,24c-0.1,0-0.2,0.1-0.2,0.1C18.6,24.3,18.8,24.2,18.9,24.1z
                 M80.5,20.1c-0.1-0.1-0.1-0.1-0.1,0.1c-0.1,0.3,0,0.5,0.1,0.3C80.5,20.3,80.5,20.2,80.5,20.1z M21.2,23.2c0-0.1-0.2,0-0.2,0.1
                c0,0.1,0,0.1,0.1,0C21.1,23.3,21.2,23.2,21.2,23.2z M92.1,19.7c0-0.1,0-0.1-0.1-0.1c0,0-0.1,0.1-0.1,0.2
                c0,0.1,0.1,0.1,0.1,0.1C92.1,19.9,92.1,19.8,92.1,19.7z M97.6,19.5C97.6,19.4,97.6,19.4,97.6,19.5c0-0.1-0.1-0.1-0.2-0.1
                c-0.1-0.1-0.2,0,0,0.1C97.5,19.7,97.5,19.7,97.6,19.5z M91.4,19.6c0-0.1,0-0.2-0.1-0.2c0,0-0.1,0,0,0.1c0,0,0,0.1,0,0.2
                c-0.1,0-0.1,0.1,0,0.1c0.1,0,0.1,0.1,0.1,0.1S91.4,19.7,91.4,19.6z M80.5,19.4c0-0.2-0.2-0.6-0.2-0.4c0,0.1,0,0.2,0,0.3
                c0,0.1,0,0.1,0.1,0c0.1-0.1,0.1-0.1,0.1,0.2c0,0.2,0,0.3,0,0.2C80.5,19.7,80.5,19.6,80.5,19.4z M91.3,19c0-0.1,0-0.1-0.1-0.1
                c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0.1-0.1c0.1,0,0.1,0,0,0c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.2,0,0,0.1
                c0.1,0.1,0.2,0.1,0.1,0.1c-0.1,0,0.2,0.3,0.3,0.3C91.3,19.2,91.3,19.1,91.3,19z M91.7,19c0-0.1,0-0.1-0.1-0.1
                c0,0-0.1,0.1-0.1,0.1c0,0.1,0.1,0.1,0.1,0.1C91.7,19.1,91.7,19.1,91.7,19z M64.6,20C64.6,20,64.6,19.9,64.6,20
                c0.1-0.1,0.1-0.1,0-0.2c-0.1-0.1-0.1-0.1-0.1-0.3c0.1-0.1,0.1-0.2,0-0.1c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0,0.1
                c0,0.2,0.1,0.4,0.1,0.4c0,0,0.1,0.1,0,0.2C64.5,20,64.5,20.1,64.6,20C64.7,20.1,64.7,20.1,64.6,20z M21.1,21.9
                C21.1,21.9,21.1,21.9,21.1,21.9c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.1,0.1c0,0.1,0.1,0.1,0.2,0C21,21.8,21,21.8,21.1,21.9z
                 M70.5,19.4c-0.1-0.1-0.2-0.1-0.2,0c0,0.1,0,0.1,0.1,0.1C70.6,19.5,70.6,19.5,70.5,19.4z M30,21.2c0,0,0,0.1,0,0.2
                c0,0.1,0,0.1,0.2-0.1C30.2,21.2,30.2,21.2,30,21.2c0-0.1-0.1-0.1-0.2,0.1c-0.1,0.2-0.1,0.2,0,0C29.9,21.2,30,21.2,30,21.2z
                 M65.4,19.5c0.1-0.1,0.1-0.1,0-0.1C65.3,19.4,65.3,19.4,65.4,19.5c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.1,0.1,0,0.1
                C65.2,19.6,65.3,19.6,65.4,19.5z M65.7,19.2c0-0.1,0-0.3-0.1-0.3c-0.1-0.1-0.1-0.1,0,0c0.1,0.1,0.1,0.2,0,0.4
                c0,0.1,0,0.2,0,0.2C65.7,19.5,65.7,19.3,65.7,19.2z M30.3,20.9c0-0.1-0.1-0.2-0.2-0.3c-0.2-0.2-0.2-0.2,0,0
                c0.1,0.1,0.1,0.2,0.1,0.3c-0.1,0.1,0,0.1,0,0.1C30.3,21.1,30.3,21,30.3,20.9z M90.6,17.8c0-0.3-0.1-0.2-0.1,0
                c0,0.1,0,0.2,0,0.2C90.6,18,90.6,17.9,90.6,17.8z M21.7,21.1C21.7,21.1,21.7,21.1,21.7,21.1c-0.1,0-0.2,0-0.3,0
                c-0.1-0.1-0.1-0.1-0.1,0C21.3,21.2,21.6,21.3,21.7,21.1z M28.9,20.8c0,0,0-0.1-0.1-0.2c-0.1-0.1-0.1-0.2-0.1-0.2
                c0-0.1,0,0-0.1,0.1c-0.1,0.1-0.2,0.1-0.2,0.1c-0.1,0-0.1,0-0.1,0c0,0,0,0.1,0,0.2c0,0.1,0.1,0.1,0.3,0.1
                C28.7,20.8,28.8,20.8,28.9,20.8z M21.8,20.8c0-0.2-0.1-0.2-0.2-0.1c-0.1,0.1-0.1,0.1,0,0.1C21.6,20.8,21.6,20.8,21.8,20.8
                C21.8,21,21.8,21,21.8,20.8z M90.6,17.4c-0.1-0.1-0.2-0.1-0.2,0.1c0,0.1,0,0.1,0.1,0C90.5,17.4,90.6,17.4,90.6,17.4z
                 M30.6,20.2c0,0-0.1-0.1-0.2-0.1c-0.2,0-0.2,0-0.1,0.2c0.1,0.1,0.1,0.2,0.2,0.1C30.6,20.3,30.6,20.2,30.6,20.2z M28.1,20.1
                c0,0-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.1-0.1c0.2,0.2,0.1,0.3-0.1,0.1c-0.1-0.1-0.2-0.2-0.2-0.2c0,0-0.1,0-0.2,0
                c-0.1,0-0.2,0.1-0.2,0.1c0,0.1,0.4,0.3,0.5,0.2c0,0,0.1,0,0.1,0c0,0.1,0.1,0.1,0.2,0C28,20,28.1,20,28.1,20.1
                C28,20.2,28,20.2,28.1,20.1C28,20.2,28.1,20.2,28.1,20.1z M41.4,19.5c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1-0.1
                c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0.1,0c0.1,0,0.1,0,0-0.1c0-0.1-0.1-0.1-0.1,0c0,0.1-0.1,0.1-0.2,0
                c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0,0,0,0.1,0.1c0.2,0.1,0.1,0.3,0,0.3c0,0-0.1-0.1,0-0.1c0-0.1,0-0.1,0-0.1
                c-0.1,0-0.1,0.1-0.1,0.2c0,0.1,0.1,0.1,0.2,0.1C41.2,19.4,41.3,19.5,41.4,19.5C41.4,19.6,41.5,19.6,41.4,19.5z M66.2,18.2
                c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0.1,0.1C66.2,18.3,66.3,18.2,66.2,18.2z M96,16.5
                c0-0.1-0.1-0.2-0.1-0.2c0,0-0.1,0-0.2,0c-0.1-0.1-0.1-0.1-0.1,0.2c0.1,0.3,0.1,0.4,0.1,0.2c0-0.3,0.2-0.2,0.2,0.1
                c0,0.1,0,0.2,0.1,0.1C96.1,16.7,96.1,16.6,96,16.5z M81.2,17.2c-0.1-0.1-0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.1,0-0.1
                c-0.1,0-0.2,0-0.2,0c-0.1-0.1-0.1-0.1-0.1,0.1c0,0.1,0,0.2,0.1,0.2c0,0,0,0.1,0.1,0.2c0,0,0.1,0,0.1,0
                C81.2,17.3,81.3,17.3,81.2,17.2z M39.4,19c0,0-0.1-0.1-0.2-0.1c-0.2,0-0.2,0.1-0.1,0.1C39.3,19.1,39.4,19.1,39.4,19z
                 M66.3,17.7c-0.2-0.1-0.3-0.2-0.3,0c0,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.2,0C66.5,17.8,66.4,17.7,66.3,17.7z M23.3,19.6
                C23.3,19.6,23.3,19.6,23.3,19.6c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.1,0c0,0.1,0.1,0.1,0.2,0.1
                C23.3,19.7,23.3,19.7,23.3,19.6z M26.4,19.5c0,0-0.1-0.1-0.2-0.1c-0.2-0.1-0.2-0.1-0.2,0c0,0.1,0.1,0.1,0.2,0.1
                C26.3,19.5,26.4,19.5,26.4,19.5z M26.9,19.4c-0.1-0.1-0.1-0.1,0-0.2c0.1,0,0,0-0.2,0c-0.3,0-0.3,0-0.3-0.1
                c0-0.3-0.1-0.5-0.2-0.6c-0.1-0.1-0.1-0.1,0,0.1c0.1,0.1,0,0.2-0.1,0.1c0,0,0,0,0.1,0.1c0.1,0.1,0.2,0.2,0.2,0.3
                c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.1-0.1-0.1,0c0,0.1,0.1,0.2,0.1,0.2c0.1,0.1,0.2,0.2,0.2,0.2C26.6,19.6,27,19.5,26.9,19.4z
                 M66.3,17.4c-0.1-0.1-0.1-0.2,0-0.2c0,0,0,0-0.1,0c-0.2,0.1-0.2,0.3-0.1,0.2C66.2,17.3,66.2,17.3,66.3,17.4c0,0.1,0,0.2,0,0.2
                C66.4,17.5,66.4,17.5,66.3,17.4z M89.6,16.3c-0.2-0.1-0.3-0.1-0.3,0C89.3,16.4,89.4,16.4,89.6,16.3
                C89.7,16.4,89.7,16.4,89.6,16.3z M41.5,18.5c-0.1-0.1-0.1-0.2,0-0.2c0.1,0,0.1,0,0-0.1c-0.1-0.1-0.6-0.1-0.6,0.1
                c0,0.1,0.1,0.1,0.2,0c0.2-0.1,0.2-0.1,0.1,0.1c-0.1,0.1-0.1,0.1,0,0.1C41.3,18.4,41.4,18.5,41.5,18.5c0,0.1,0,0.2,0.1,0.2
                C41.6,18.7,41.6,18.6,41.5,18.5z M29.3,18.8c0.2-0.2,0.3-0.4,0.1-0.4c0,0-0.1,0-0.1,0.1c0,0.1-0.2,0.4-0.3,0.3c0,0,0,0,0,0.1
                C29,19.1,29.1,19.1,29.3,18.8z M89,16C89.1,16,89.1,16,89,16c-0.1-0.1-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.2-0.1
                c-0.2,0-0.2,0-0.1,0.1c0.1,0,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0.1,0C88.7,16.1,88.9,16,89,16z M25.7,18.6
                C25.6,18.5,25.6,18.4,25.7,18.6c0.1-0.1,0.2-0.1,0.2-0.2c0-0.1,0-0.1-0.2,0c-0.1,0-0.2,0.1-0.2,0c0-0.1-0.2,0.2-0.2,0.3
                c0,0.1,0,0.1,0,0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0.1c0,0,0,0.1,0.1,0c0,0,0.1,0,0.1,0.1
                c0,0.1,0,0.1,0.1,0C25.8,18.8,25.7,18.7,25.7,18.6z M38,18.1c-0.1-0.1-0.2-0.1-0.2-0.1c-0.1,0,0,0,0.1-0.1
                c0.2-0.1,0.1-0.1-0.2,0c-0.3,0-0.5,0-0.5-0.1c0-0.1,0,0,0,0c0,0.1,0.1,0.2,0.5,0.2c0.3,0,0.4,0.1,0.3,0.1
                c-0.1,0.1-0.1,0.1,0.1,0.1C38.1,18.3,38.1,18.2,38,18.1z M49.9,17.6c0,0,0-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0c0,0.1,0,0.1,0,0.1
                c-0.1,0-0.1-0.1-0.1-0.3c0,0,0-0.1-0.1,0c-0.1,0-0.1,0.1,0,0.2C49.6,17.7,49.9,17.8,49.9,17.6z M95.3,15.3
                c-0.1-0.2-0.2-0.2-0.1,0c0.1,0.1,0.1,0.1-0.1,0c-0.1-0.1-0.2-0.1-0.2,0c0,0.1,0.1,0.2,0.3,0.2C95.3,15.5,95.4,15.4,95.3,15.3z
                 M87.9,15.5c-0.1-0.1-0.2,0-0.2,0.1c0,0.1,0.1,0.1,0.1,0C87.9,15.6,87.9,15.6,87.9,15.5z M40.8,17.7c-0.1-0.1-0.1-0.2,0-0.2
                c0,0,0-0.1,0,0C40.6,17.6,40.6,17.8,40.8,17.7c0,0,0,0.1,0,0.2c0,0.1,0,0.1,0,0.1C40.8,17.8,40.8,17.8,40.8,17.7z M67,16.6
                c0.1,0.1,0.1,0,0-0.1c-0.1-0.3,0.1-0.5,0.3-0.4c0.2,0,0.2,0,0-0.1c-0.1-0.1-0.2-0.1-0.2,0c0,0.1-0.1,0.1-0.1,0
                c0,0,0,0-0.1,0.1c0,0.1-0.1,0.2,0,0.2c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0.1,0C66.9,16.5,66.9,16.5,67,16.6z
                 M82.2,15.7C82,15.6,82,15.6,82.2,15.7c0.2,0,0.3,0,0.1-0.1c-0.1-0.1-0.1-0.1,0-0.1c0.1,0,0.2,0,0.2,0.1c0,0,0,0,0,0
                c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0-0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.1,0.1-0.2,0.1s-0.1,0-0.1,0c0,0.1,0,0.2,0,0.2
                c0,0.2,0,0.2,0.2,0.2C82.3,15.8,82.3,15.8,82.2,15.7z M88.3,15.4c0.1-0.1,0-0.2,0-0.2c-0.1,0-0.1,0.1-0.1,0.2
                c0,0.1,0,0.2,0,0.2S88.2,15.5,88.3,15.4z M50.7,17.2C50.8,17.1,50.8,17.1,50.7,17.2c-0.1,0-0.2,0-0.2-0.1c0,0,0-0.1-0.1-0.1
                c-0.1,0-0.1,0,0,0.2C50.6,17.4,50.6,17.4,50.7,17.2z M57.3,16.8c0-0.1,0-0.1,0-0.1c-0.2,0-0.2,0-0.1-0.1
                c0.1-0.1,0.1-0.1,0-0.1c-0.2,0-0.2,0.1-0.1,0.4C57.2,17,57.3,17,57.3,16.8z M95,15C95,15,95,15,95,15c-0.1,0-0.1,0-0.1-0.2
                c0-0.2,0-0.2-0.2-0.2c-0.1,0-0.2,0-0.2,0.1c0,0,0.1,0.1,0.1,0c0.1,0,0.1,0,0.1,0.1C94.8,15.1,94.9,15.2,95,15z M57.8,16.5
                c0-0.2,0-0.4-0.1-0.4c0,0-0.1,0.1,0,0.4C57.7,17,57.8,17,57.8,16.5z M29.3,17.4c0-0.3-0.2-0.2-0.2,0.2
                c-0.1,0.6-0.1,0.7,0.1,0.3C29.2,17.7,29.3,17.5,29.3,17.4z M36.7,17.5c0,0,0.1-0.1,0.2,0c0.2,0,0.1,0-0.1-0.1
                c-0.1-0.1-0.3-0.1-0.3-0.1c0,0,0,0.1,0.1,0c0.1,0,0.1,0,0,0.1c-0.2,0.2-0.3,0.2-0.2,0c0-0.1,0-0.1,0,0c-0.1,0-0.1,0.1,0,0.2
                c0,0.1,0.1,0.1,0.3,0C36.7,17.7,36.7,17.6,36.7,17.5z M48.8,16.8c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0,0,0.1
                c0.1,0.1,0.1,0.1,0,0.1c-0.1,0-0.1,0,0,0.1c0,0.1,0.1,0.1,0.1,0C48.8,16.9,48.8,16.8,48.8,16.8z M56.8,16.4
                c-0.1-0.1-0.1-0.1,0-0.3c0.1-0.1,0.1-0.1,0-0.1c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1-0.3,0c0,0,0,0.1,0.1,0.1
                c0.1,0.1,0.1,0.2,0.1,0.2C56.6,16.3,56.6,16.3,56.8,16.4c0,0,0,0.1,0,0.2c0,0.1,0,0.1,0.1,0C56.9,16.5,56.9,16.5,56.8,16.4z
                 M50.2,16.7c-0.1-0.2-0.2-0.3-0.2-0.2c0,0.1,0.2,0.4,0.2,0.4C50.3,16.9,50.3,16.8,50.2,16.7z M40.8,17.2c0,0-0.1-0.1-0.1-0.2
                c-0.1-0.1-0.1-0.1-0.1,0c0,0.1,0,0.2,0,0.2c-0.1,0,0,0,0.1,0C40.7,17.3,40.8,17.2,40.8,17.2z M84.1,15c0-0.1,0-0.1-0.1-0.1
                c0,0-0.1,0-0.1,0c0,0-0.2,0-0.4,0c-0.2,0-0.3,0-0.2,0c0.1,0,0.1,0,0,0.1c-0.1,0.1,0,0.1,0.2,0c0.3,0,0.3,0,0.2,0
                c-0.2,0.1-0.2,0.1,0.1,0.1C84,15.1,84.1,15.1,84.1,15z M85.9,15c0.1,0.1,0.2,0.1,0.2,0c0.1-0.1,0.1-0.1,0-0.1
                c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-0.5-0.1-0.4,0.1c0,0.1,0.1,0.1,0.2,0C85.7,14.9,85.8,14.9,85.9,15z M94.5,14.5
                C94.6,14.5,94.6,14.5,94.5,14.5c0.1-0.1,0.1-0.1,0-0.1c0,0-0.1-0.1-0.1-0.1c0-0.2-0.1-0.1-0.1,0.2c0,0.2,0,0.2,0,0.1
                C94.4,14.5,94.5,14.5,94.5,14.5z M36,17.3c0,0,0-0.1,0.1-0.2c0.1-0.1,0.1-0.1,0-0.1c-0.1,0-0.1,0.1-0.1,0.1c0,0,0,0.1-0.1,0.2
                c-0.1,0.1-0.1,0.1,0,0.1C36,17.4,36,17.3,36,17.3z M67.7,15.6C67.6,15.5,67.7,15.5,67.7,15.6c0.1,0,0.2,0,0.1-0.1
                c0-0.1-0.1-0.1-0.1,0c0,0-0.1,0-0.1-0.1c0-0.1,0,0,0,0.1c0,0.2,0,0.2-0.1,0.1c-0.1,0-0.2,0-0.2,0.1c0,0,0.1,0.1,0.1,0
                c0.1,0,0.2,0,0.2,0C67.6,15.9,67.8,15.8,67.7,15.6z M85,14.8c0.2-0.1,0.3-0.1,0.2-0.1c-0.1,0-1,0.3-1,0.4
                C84.3,15.1,84.6,15,85,14.8z M40.3,17c0-0.1,0-0.1,0.1-0.1c0.1,0,0.1-0.1,0.1-0.2c0-0.2,0-0.2-0.1,0
                C40.2,16.9,40.1,17,40.3,17C40.2,17.2,40.3,17.1,40.3,17z M49.9,16.2c-0.1-0.1-0.1-0.1,0-0.1c0,0,0-0.1-0.1-0.1
                c-0.2-0.1-0.2-0.1-0.1,0c0,0.1,0.1,0.2,0.1,0.3c0,0.3,0,0.3,0.1,0.2C50,16.4,50,16.3,49.9,16.2z M50.7,16.3
                c0,0-0.1-0.1-0.1-0.3c0-0.2-0.1-0.2-0.1-0.1c0,0.1,0,0.2,0.1,0.4c0.1,0.1,0.2,0.2,0.2,0.1C50.7,16.4,50.7,16.3,50.7,16.3z
                 M35.7,17.1c0.1-0.1,0.1-0.1-0.1-0.2c-0.1-0.1-0.2-0.1-0.3-0.1c0,0-0.1,0-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0-0.1,0,0,0.1
                c0,0.1,0,0.1,0,0c-0.1,0-0.1-0.1-0.1-0.1c0,0,0-0.1-0.1-0.2c-0.1-0.1-0.1-0.1-0.1,0c0.1,0.1,0,0.1-0.1,0.1
                c-0.1,0-0.1,0-0.1,0.1c0,0.1,0.1,0.1,0.1,0.1c0,0,0.1,0,0.2,0.1c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0,0.1c0,0.1,0,0.1,0.1,0
                c0.1-0.1,0.2-0.1,0.2,0c0.1,0.1,0.1,0.1,0,0.1c-0.1,0-0.1,0-0.1,0.1C35.5,17.2,35.6,17.2,35.7,17.1z M64.3,15.2
                c0-0.2,0-0.5,0-0.6c0-0.1,0-0.2,0-0.1c0,0.1-0.1,0.1-0.1,0c0-0.1,0,1,0,1.1C64.3,15.8,64.4,15.6,64.3,15.2z M48,16.3
                c0-0.1,0-0.1,0.1-0.1c0.1,0,0.1,0,0-0.1c0-0.1-0.1-0.1-0.1,0c0,0.1-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1c0,0.1,0,0.2,0.1,0.2
                C48,16.4,48.1,16.3,48,16.3z M47.5,15.8c0-0.1,0-0.3,0-0.3c0.1,0,0.1,0,0.1,0.1c0,0,0,0.1,0.1,0c0.1,0,0.1-0.1,0-0.2
                c-0.1-0.1-0.1-0.1-0.2,0c-0.1,0.1-0.1,0.1-0.1,0.2c0,0,0,0.1-0.1,0c-0.1,0-0.1,0-0.1,0.1c0,0.1,0.1,0.1,0.1,0.1
                c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0.1C47.5,16.1,47.5,15.9,47.5,15.8z M40,16.3c0,0,0-0.1,0-0.2c0.1-0.1-0.1-0.5-0.2-0.5
                c-0.1,0,0,0.1,0.1,0.2c0.2,0.2,0.2,0.3,0.1,0.2c-0.1,0-0.1,0-0.1,0c0,0,0,0.1,0,0.2C39.8,16.3,39.8,16.3,40,16.3
                C40,16.3,40,16.3,40,16.3z M93.8,13.5c0-0.1,0-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1C93.5,13.7,93.7,13.7,93.8,13.5z M58.7,15.1
                c0-0.1,0-0.3,0.1-0.3c0.1,0,0.1-0.1,0-0.2c-0.1-0.1-0.1-0.1-0.3,0.1c-0.1,0.1-0.1,0.3-0.1,0.3c0,0.1,0,0,0.1,0
                c0.1-0.1,0.1-0.1,0.1,0c0,0.3,0,0.4,0.1,0.4C58.6,15.3,58.7,15.2,58.7,15.1z M34.5,16.3c-0.1-0.2-0.3-0.3-0.5-0.2
                c-0.1,0.1-0.1,0.1,0,0.2c0.1,0,0.2,0,0.2,0c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0,0.1c0,0.1,0,0.1,0.2,0.2
                C34.5,16.4,34.5,16.4,34.5,16.3z M93.6,13.2c-0.1-0.1-0.2-0.2-0.2-0.2c-0.1,0,0.2,0.3,0.3,0.3C93.8,13.3,93.7,13.3,93.6,13.2z
                 M58.4,14.2c0-0.4,0-0.5,0.1-0.3c0.1,0.1,0.1,0.1,0.2-0.1c0.2-0.3,0.1-0.3-0.2-0.1c-0.2,0.1-0.3,0.1-0.2-0.1c0,0,0-0.1-0.1,0
                c-0.1,0-0.1,0.1,0,0.2c0.1,0.1,0.1,0.2,0,0.3c-0.1,0.1-0.1,0.2,0,0.2c0.1,0,0.1,0.1,0,0.1c-0.1,0-0.1,0.1-0.1,0.2
                C58.1,14.8,58.4,14.4,58.4,14.2z M49.7,14.7C49.7,14.6,49.6,14.6,49.7,14.7c-0.1,0.1-0.1,0.1-0.2,0c-0.1-0.1-0.1-0.1,0,0
                c0.1,0.1,0.1,0.2,0.1,0.2c0,0,0,0,0.1,0C49.6,14.8,49.7,14.7,49.7,14.7z M33.5,15.5C33.4,15.4,33.4,15.4,33.5,15.5
                c0.1,0,0.2,0.1,0.3,0.1c0.2,0-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.1,0c0.1,0,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0.1,0.1
                C33.5,15.7,33.5,15.6,33.5,15.5z M46.5,14.6C46.4,14.5,46.4,14.5,46.5,14.6c0.2,0,0.2,0,0.1-0.1
                C46.5,14.3,46.3,14.5,46.5,14.6c0,0.1,0,0.2,0,0.2c-0.1,0-0.1,0,0,0.1c0,0.1,0.1,0.1,0.1,0C46.6,14.8,46.6,14.7,46.5,14.6z
                 M49.9,14.6c0-0.1-0.1-0.2-0.1-0.2c0,0-0.1,0,0-0.1c0-0.1,0-0.1,0-0.1c-0.1,0.1-0.1,0.2,0,0.3C49.9,14.8,49.9,14.8,49.9,14.6z
                 M64.8,13.6C64.8,13.6,64.8,13.6,64.8,13.6c0-0.2-0.1-0.1-0.2,0.1l-0.1,0.2l0.2-0.2C64.6,13.6,64.7,13.6,64.8,13.6z M33.1,15
                c-0.1-0.2-0.1-0.1-0.2,0.1c0,0.2,0,0.2,0.1,0.1C33.1,15.2,33.1,15.1,33.1,15z M39.2,14.9c0-0.3-0.1-0.3-0.1,0
                c0,0.1,0,0.2,0.1,0.2C39.2,15,39.2,15,39.2,14.9z M92.4,12.4c0-0.1-0.2-0.2-0.2-0.1C92.1,12.3,92.2,12.4,92.4,12.4
                C92.4,12.4,92.4,12.4,92.4,12.4z M55.8,13.7c0-0.1,0-0.1,0-0.2c-0.1-0.1-0.1-0.1,0-0.1c0.1,0,0.2,0,0.1-0.1
                c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.2,0.1-0.2,0.1c0,0,0,0.2,0,0.5c0,0.2,0.1,0.4,0.1,0.2C55.7,13.8,55.7,13.7,55.8,13.7z
                 M56.2,13.9c-0.1-0.2-0.2-0.2-0.1,0.1c0,0.1,0.1,0.1,0.2,0.1C56.3,14.1,56.3,14.1,56.2,13.9z M32.8,14.8
                c-0.1-0.1-0.4-0.1-0.3,0c0,0,0.1,0,0.1,0C32.6,14.8,32.7,14.8,32.8,14.8c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0,0,0,0.1,0
                C32.8,15,32.9,14.9,32.8,14.8z M46.2,13.9c0-0.2,0-0.3-0.1-0.3c-0.1,0-0.1,0,0,0.1c0.1,0.1,0,0.1-0.1,0
                c-0.1-0.1-0.1-0.2,0-0.3c0.1-0.1,0.1-0.1,0,0c-0.1,0-0.2,0-0.3-0.2c-0.1-0.1-0.2-0.3-0.2-0.3c-0.1,0-0.1,0.1,0.2,0.3
                c0.1,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.1,0,0.1c0.2,0,0.2,0,0.1,0.3c-0.1,0.2-0.1,0.2,0,0.1c0.1-0.1,0.2-0.2,0.2-0.1
                c0,0,0,0.1-0.1,0.2c-0.1,0.2-0.1,0.2,0,0.1c0.1,0,0.1,0,0.2,0C46,14.4,46.2,14.2,46.2,13.9z M92.1,11.8
                C92.2,11.8,92.2,11.8,92.1,11.8c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0-0.1c0,0-0.1,0.2-0.1,0.3
                c0,0.2,0,0.2,0.1,0.1C91.9,11.8,92,11.7,92.1,11.8z M32.3,14.5c0-0.1-0.1-0.2-0.1-0.3c-0.1,0-0.1-0.1-0.1-0.2
                c0-0.1,0-0.1,0-0.1c-0.1,0.1-0.5-0.3-0.5-0.4c0-0.2-0.1-0.3-0.2-0.1c-0.1,0.1-0.1,0.2,0,0.3c0.2,0.2,0.2,0.3,0,0.2
                c-0.1,0-0.1,0,0,0.1c0.1,0.2,0.2,0.2,0.1,0c0-0.1,0-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.1
                c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.1,0.2,0.1,0.2c0,0,0,0,0,0.1C32.3,14.8,32.3,14.8,32.3,14.5z M58.8,13.4
                c0.2-0.2,0.2-0.4,0-0.4c-0.1,0-0.1,0.1-0.1,0.1c0,0,0,0.1-0.1,0.2c-0.1,0.1-0.1,0.2,0,0.2C58.7,13.5,58.8,13.4,58.8,13.4z
                 M28.4,14.6c0-0.1,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0c0,0,0-0.1,0-0.2c0.1-0.1,0-0.1-0.1,0C28.2,14.5,28.2,14.6,28.4,14.6
                C28.4,14.8,28.4,14.8,28.4,14.6z M64.9,12.7C65,12.7,65,12.6,64.9,12.7c0.1-0.1,0-0.2-0.1-0.2c-0.1,0-0.1,0.1-0.1,0.2
                c0,0.1,0,0.1,0.1,0.1C64.8,12.7,64.9,12.6,64.9,12.7z M29,14.3c0.1-0.1,0.3-0.2,0.2-0.3c0-0.1,0-0.1-0.1,0
                c0,0.1-0.2,0.2-0.3,0.3C28.6,14.5,28.7,14.5,29,14.3z M55.8,12.9c-0.1,0-0.1-0.1-0.1-0.2c0.1-0.2-0.1-0.3-0.2-0.1
                c0,0.1,0,0.1,0.1,0c0.1-0.1,0.1,0,0.1,0.1c-0.1,0.2,0,0.4,0.2,0.4C55.9,13,55.9,12.9,55.8,12.9z M39.2,13.6
                c0-0.2,0-0.2-0.1-0.1c-0.1,0.2-0.1,0.4,0,0.3C39.2,13.8,39.2,13.7,39.2,13.6z M90.9,11c0-0.1-0.2-0.2-0.2-0.1
                C90.6,10.9,90.7,11.1,90.9,11C90.9,11.1,90.9,11.1,90.9,11z M45.5,12.7c-0.3-0.2-0.4-0.5-0.1-0.3c0.1,0.1,0,0-0.1-0.1
                C45.1,12.2,45,12.1,45,12c0,0,0-0.1-0.1-0.1c-0.1,0-0.1,0,0,0.2c0.1,0.1,0.1,0.2,0,0.3c0,0,0,0,0.1,0c0.1,0,0.1,0,0.1,0.1
                c0,0.1,0.4,0.6,0.6,0.6C45.8,12.9,45.7,12.8,45.5,12.7z M59.6,12.1C59.7,12.2,59.7,12.2,59.6,12.1c-0.1-0.2-0.2-0.2-0.2-0.1
                c-0.2,0.2-0.3,0.1-0.1-0.1c0.1-0.1,0.1-0.2,0.1-0.2c-0.1,0-0.4,0.4-0.3,0.5c0,0,0.1,0,0.3-0.1C59.4,12.1,59.5,12,59.6,12.1z
                 M70.7,11.5C70.7,11.3,70.7,11.2,70.7,11.5c-0.2,0.2-0.2,0.2-0.1,0.2C70.7,11.7,70.8,11.6,70.7,11.5z M31.2,13.3
                C31.1,13.2,31.1,13.2,31.2,13.3c0.2,0,0.2-0.2-0.1-0.3C31,13,30.9,13,31,13.1c0,0.1,0,0.2,0,0.2c-0.2,0,0,0.1,0.2,0.1
                C31.3,13.5,31.3,13.4,31.2,13.3z M55.7,11.8c0-0.2,0-0.3,0-0.3c-0.1,0,0-0.1,0.1-0.1c0.1-0.1,0.1-0.1,0-0.2
                c-0.1,0-0.1,0-0.1,0c0,0.1,0,0.1,0,0.1c0,0-0.1,0-0.1-0.1c0-0.1,0,0.1,0,0.4C55.5,12.2,55.6,12.2,55.7,11.8z M38.3,12.6
                c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.1c-0.1,0-0.1,0-0.1,0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0.1,0.1
                C38.4,12.7,38.4,12.6,38.3,12.6z M63.9,11.3c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1c0,0.1,0.1,0.1,0.1,0.1
                C63.8,11.3,63.9,11.3,63.9,11.3z M89.6,9.8C89.7,9.7,89.6,9.7,89.6,9.8c-0.1,0-0.1-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.1
                c-0.2,0-0.2,0.3,0,0.3c0.1,0,0.2,0,0.2,0C89.6,9.9,89.6,9.8,89.6,9.8z M63.5,10.9c0-0.1-0.1-0.1-0.1-0.1
                c-0.1,0-0.2,0.2-0.1,0.3C63.4,11.1,63.5,11,63.5,10.9z M88.5,8.9c-0.1-0.1-0.1-0.1-0.1-0.1c0,0.2,0.1,0.4,0.2,0.3
                C88.6,9.1,88.5,9,88.5,8.9z M37.9,10.9c0,0,0.1,0,0.1-0.1C38,10.8,38,10.8,37.9,10.9c-0.1,0-0.2,0.1-0.2,0.2
                c0,0.1,0,0.1,0.1,0C37.8,11,37.9,10.9,37.9,10.9z M49.6,10.3c0-0.2,0-0.3-0.1-0.3c0,0-0.1-0.1,0-0.1c0-0.1,0-0.1,0,0
                c-0.1,0.1-0.1,0.8,0,0.8C49.5,10.6,49.6,10.5,49.6,10.3z M61.3,9.9c0-0.2-0.1-0.2-0.3,0C61,10,61,10,61,9.9
                c0-0.1,0-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0.1c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.2,0-0.2-0.1c0-0.1,0-0.1-0.1,0
                C60.4,10,60.6,10,60.9,10C61.2,10,61.3,10,61.3,9.9z M87.9,8.6c0,0-0.2-0.2-0.4-0.3c-0.2-0.1-0.3-0.1-0.2,0
                c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.1,0.1c0,0,0,0.1,0,0.1C87.9,8.7,87.9,8.7,87.9,8.6z M61.8,9.5c0-0.1,0-0.1-0.1-0.1
                c0,0-0.1,0.1-0.1,0.1c0,0.1,0.1,0.1,0.1,0.1C61.7,9.7,61.8,9.6,61.8,9.5z M55.7,9.5c0-0.1,0-0.3,0-0.4
                c0.1-0.2,0.1-0.3-0.1-0.2c-0.1,0-0.1,0.2-0.1,0.4c0,0.3,0,0.3,0,0.2C55.6,9.4,55.6,9.4,55.7,9.5c0,0.2,0,0.2,0,0.2
                C55.7,9.7,55.7,9.6,55.7,9.5z M44.2,9.8c-0.1-0.1-0.1,0-0.1,0.2c0,0.2,0,0.2,0.1,0.1C44.3,9.9,44.3,9.9,44.2,9.8z M49.5,9.5
                l0-0.2L49.5,9.5c-0.1,0.1-0.1,0.1-0.2,0.1c0-0.1,0-0.1-0.1-0.1c-0.1,0.1,0,0.2,0.2,0.2C49.5,9.7,49.5,9.6,49.5,9.5z M85.7,7.2
                c0.1-0.1,0.1-0.1-0.1-0.2c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1,0c0,0.1,0.1,0.1,0.1,0.2
                c0.1,0.1,0.1,0.1,0,0.2C85.2,7.4,85.5,7.3,85.7,7.2z M84.7,6.5C84.7,6.4,84.7,6.4,84.7,6.5c-0.2,0.1-0.2,0-0.6-0.3
                c-0.1-0.1-0.2-0.1-0.2,0c0,0.1,0.5,0.5,0.7,0.5C84.6,6.7,84.7,6.6,84.7,6.5z M50.2,7.7c0,0,0-0.1,0-0.1
                c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.1-0.1,0c-0.1,0.1-0.2,0.2-0.2,0.3C49.7,7.7,49.7,7.8,50.2,7.7z M82.5,5.7
                c-0.1-0.1-0.3-0.2-0.3-0.2c0,0-0.1-0.1-0.3-0.1c-0.2-0.1-0.2-0.1,0,0.1c0.3,0.2,0.5,0.3,0.7,0.3C82.7,5.8,82.7,5.8,82.5,5.7z
                 M50.2,6.8c-0.1-0.1-0.1-0.2-0.1-0.1c0,0.2,0.1,0.4,0.2,0.4C50.3,7.1,50.2,7,50.2,6.8z M56.1,6.8c0-0.2,0-0.4,0.1-0.4
                c0,0,0.1-0.1,0-0.2C56.2,6,56.1,6,56.1,6.4C55.9,6.9,55.9,6.8,56.1,6.8C56.1,6.8,56.1,6.8,56.1,6.8z M40.2,7.2
                C40.1,7.2,40.1,7.2,40.2,7.2c0.1-0.1,0.1-0.2,0-0.3c-0.1,0-0.1,0-0.1,0.2c0,0.2,0,0.2,0.1,0.2C40.3,7.3,40.3,7.3,40.2,7.2z
                 M80.9,4.7c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.2,0.1C80.5,4.8,80.8,4.8,80.9,4.7z M54.7,5.7c0,0,0-0.1,0.1-0.1
                c0.1,0,0.1,0,0-0.1c-0.2-0.1-0.3-0.1-0.2,0c0,0.1,0,0.1-0.1,0c-0.1,0-0.2,0-0.2,0c0,0,0.1,0.1,0.1,0.1
                C54.7,5.9,54.8,5.8,54.7,5.7z M43.2,6.2c0-0.1-0.2-0.1-0.5,0c-0.1,0.1-0.1,0.1,0.2,0.1C43.1,6.3,43.2,6.3,43.2,6.2z M43.8,6.1
                c0.1-0.1,0.1-0.1-0.1-0.1c-0.1,0-0.2,0.1-0.2,0.1c0,0.1,0.1,0.1,0.1,0.1C43.7,6.2,43.8,6.1,43.8,6.1z M53.4,5.4
                c0,0-0.1-0.1-0.2-0.1C53.1,5.2,53,5.2,53,5.3c0,0.1,0.1,0.1,0.2,0.1C53.3,5.5,53.4,5.4,53.4,5.4z M79,4c0,0-0.1,0-0.2,0
                c-0.1,0-0.2,0.1-0.1,0.1C78.8,4.2,79,4,79,4z M44,5.7C44,5.6,44,5.6,44,5.7c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.1,0.1,0,0
                C43.8,5.8,43.9,5.7,44,5.7z M52.2,5.3c-0.1-0.1-0.6-0.1-0.6,0c0,0.1,0.2,0.1,0.3,0.1C52.1,5.3,52.2,5.3,52.2,5.3z"/>
              <path className="main-textlogo" d="M28.3,20.7C28.3,20.7,28.3,20.6,28.3,20.7c0.1,0,0.2,0,0.2-0.1c0,0,0.1-0.1,0.1,0c0.1,0,0.1,0.1,0.1,0.1
                C28.8,20.8,28.5,20.8,28.3,20.7z"/>
              <path className="main-textlogo" d="M70.4,18.9c0,0,0-0.1,0-0.1c0-0.1,0.1,0,0.1,0.1C70.6,19,70.5,19,70.4,18.9C70.4,19,70.4,19,70.4,18.9z"
                />
              <path className="main-textlogo" d="M64.8,11.4l-0.3-0.3l0.2,0.2c0.1,0.1,0.3,0.2,0.3,0.3C65.2,11.8,65.1,11.7,64.8,11.4z"/>
            </g>
            <g>
              <path className="st7" d="M86.6,25.5c-0.2,0-0.5,0-0.6,0c-0.1,0-0.2,0-0.2,0c0-0.1-0.1-0.1-0.1,0c-0.1,0-0.2,0-0.3-0.1
                c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.2-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.1
                c0,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.4-0.3-0.5-0.4c-0.3-0.2-0.6-0.5-1-0.9c-0.2-0.2-0.3-0.4-0.4-0.4c-0.1,0-0.1,0-0.1-0.1
                c0-0.1-0.2-0.4-0.4-0.7c-0.2-0.3-0.4-0.7-0.4-0.9c0-0.2-0.1-0.3-0.1-0.4c0-0.1-0.1-0.6-0.1-1.2c0-1,0-1.2,0.3-2.1
                c0.2-0.4,0.6-1.2,0.8-1.2c0.1,0,0.1-0.1,0.2-0.1c0.2-0.3,1.2-0.8,2.1-1c0.9-0.2,1.5-0.2,2.4,0c1.6,0.4,3.4,1.7,4.2,3.2
                c1.1,2,1,4.1-0.3,5.6C89.4,25.2,88,25.7,86.6,25.5z"/>
              <path className="st7" d="M64.7,19.3c-0.1-0.7-0.2-3.4-0.2-3.9c0.1-0.9,0.1-1.1,0.3-1.8c0.2-0.7,0.6-1.6,0.7-1.6
                c0.1,0,0.9,0.9,1.7,2l0.7,0.8l-0.4,0.5c-0.4,0.5-0.8,1-1.5,2.1c-0.5,0.7-1.1,2.2-1.1,2.4c0,0.1,0,0.2-0.1,0.2
                C64.8,20,64.7,19.7,64.7,19.3z"/>
              <path className="st7" d="M29.1,20.4c-0.6-0.3-1.1-0.6-1.3-0.7c-0.2-0.1-0.3-0.2-0.3-0.2c-0.2,0-2.5-2-2.6-2.2c0,0,0.4-0.4,0.9-0.9
                c1.4-1.3,1.6-1.5,1.7-1.3c0,0.1,0.1,0.5,0.3,0.9c0.5,1.6,1.2,3.2,1.9,4.1c0.5,0.7,0.5,0.7,0.5,0.7
                C30.1,20.9,29.7,20.7,29.1,20.4z"/>
              <path className="st7" d="M40.9,18.9c-0.1,0-0.6-0.2-1.1-0.4c-1-0.3-2.9-1.1-3.7-1.6c-0.3-0.2-0.5-0.3-0.6-0.3c0,0-0.4-0.2-0.9-0.6
                c-1.4-1-3.5-3-3.8-3.7c-0.1-0.2,1.1-1.1,3-2.3c1.3-0.8,3.1-1.9,3.3-1.9c0.1,0,0.2,0.4,0.2,1c0.1,0.9,0.3,1.7,0.8,3.4
                c0.4,1.5,1.6,4,2.6,5.5c0.3,0.4,0.5,0.7,0.5,0.8C41.3,19,41.1,18.9,40.9,18.9z"/>
              <path className="st7" d="M49.7,17.2c-1-0.7-2.8-2.5-3.4-3.4c-1.2-1.9-1.7-3.2-2.1-5.1c-0.2-0.9-0.3-3.1-0.2-3.2
                c0.2-0.1,1.8-0.4,3.2-0.6c1.1-0.1,3-0.2,3.1-0.1c0,0-0.1,0.4-0.2,1c-0.1,0.5-0.3,1.2-0.4,1.6c-0.1,0.4-0.2,0.9-0.3,1.1
                c-0.1,0.6-0.3,1.9-0.3,2.8c0,0.4,0,0.9,0,1.1c-0.1,0.9,0.4,3.5,0.8,4.2c0.2,0.4,0.5,1.1,0.5,1.1C50.3,17.6,50,17.4,49.7,17.2z
                "/>
              <path className="st7" d="M57.4,16.8c-0.4-0.8-0.8-1.9-1.1-3c-0.3-1.1-0.5-3.4-0.4-4.5C56,7.9,56.5,6,56.8,6c0.4,0,3.6,1.6,4.6,2.4
                c0.2,0.1,0.1,0.2-0.5,1c-0.7,0.8-1.6,2-1.7,2.4c-0.1,0.1-0.2,0.3-0.3,0.5C58.6,12.6,58,14,58,14.2c0,0.1,0,0.2-0.1,0.2
                c-0.1,0.2-0.3,1-0.3,1.9l0,0.9L57.4,16.8z"/>
            </g>
            <g>
              <path className="st8" d="M86.3,25.4c-0.4-0.1-0.9-0.2-1.2-0.3S84.6,25,84.5,25c-0.1,0-0.2-0.1-0.3-0.2c0-0.1-0.2-0.2-0.2-0.2
                c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c-0.3-0.2-0.6-0.5-1-0.9c-0.2-0.2-0.3-0.4-0.3-0.4c0,0-0.1-0.1-0.2-0.2
                c-0.1-0.1-0.2-0.3-0.3-0.5c-0.8-1.5-1-3.5-0.3-4.6c0-0.1,0.1-0.2,0.2-0.4c0.1-0.2,0.7-1,0.9-1.1c0.1,0,0.3-0.2,0.5-0.3
                c0.4-0.3,1.7-0.7,2.3-0.7c1.1-0.1,2.8,0.5,3.9,1.4c1.8,1.4,2.7,3.5,2.5,5.4c-0.2,1.6-1.5,3.1-3.1,3.5
                C87.5,25.6,87.2,25.6,86.3,25.4z"/>
              <path className="st8" d="M64.7,19.3c-0.2-1-0.3-3.4-0.2-4.2c0.1-0.4,0.2-0.9,0.2-1.3c0.2-0.6,0.7-1.9,0.8-1.9
                c0.1,0,0.8,0.8,1.8,1.9l0.7,0.8l-0.5,0.6c-1.2,1.4-2.1,3-2.6,4.5C64.8,20,64.8,19.9,64.7,19.3z"/>
              <path className="st8" d="M29.1,20.4c-0.5-0.3-1.3-0.7-1.8-1c-0.7-0.5-2.4-1.9-2.4-2.1c0,0,0.4-0.4,0.9-0.9
                c1.4-1.3,1.6-1.5,1.7-1.3c0,0.1,0.1,0.5,0.3,0.9c0.5,1.6,1.2,3.2,1.9,4.1c0.5,0.7,0.5,0.7,0.5,0.7
                C30.1,20.9,29.7,20.7,29.1,20.4z"/>
              <path className="st8" d="M40.6,18.8c-2.3-0.6-4.7-1.8-6.5-3.1c-1.2-0.8-3-2.7-3.2-3.3c-0.1-0.2,1.1-1.1,3-2.3
                c1.3-0.8,3.1-1.9,3.3-1.9c0.1,0,0.1,0.3,0.2,0.8c0.4,3,1.8,6.7,3.5,9.3c0.2,0.3,0.4,0.6,0.4,0.7
                C41.2,18.9,40.9,18.8,40.6,18.8z"/>
              <path className="st8" d="M49.7,17.3c-0.8-0.5-2.3-2-3-2.9C45,12,44.2,9.7,44,6.9c0-0.9,0-1.3,0-1.4c0.2-0.1,1.8-0.4,3.2-0.6
                c1.1-0.1,3-0.2,3.1-0.1c0,0-0.1,0.3-0.2,0.7c-0.9,3-1.3,6.1-1,8.4c0.2,1.2,0.5,2.5,0.9,3.3c0.1,0.3,0.2,0.5,0.2,0.5
                C50.3,17.6,50,17.4,49.7,17.3z"/>
              <path className="st8" d="M57.4,16.8c-0.4-0.7-0.7-1.6-1-2.7c-0.3-1.1-0.6-3.5-0.5-4.7C56,8,56.5,6,56.8,6c0.4,0,3.6,1.6,4.6,2.4
                c0.2,0.1,0.1,0.2-0.4,0.8c-1.2,1.4-2.1,2.8-2.7,4.2c-0.5,1.1-0.6,1.8-0.6,2.9l0,0.9L57.4,16.8z"/>
            </g>
            <g>
              <path className="st9" d="M86.6,25.5c-0.2,0-0.5-0.1-0.5-0.2c0-0.1-0.3-0.2-0.6-0.3c-1.8-0.7-3.3-2.3-3.8-4.2c-0.6-1.9,0-3.9,1.5-5
                c0.3-0.2,0.6-0.4,0.6-0.4c0.1,0,0.1,0,0.1-0.1c0-0.1,0.6-0.3,1.1-0.3c1.6-0.1,3.8,1,5,2.4c1.1,1.3,1.6,3,1.4,4.4
                c-0.2,1.6-1.5,3.1-3.1,3.5C87.6,25.6,87.2,25.6,86.6,25.5z"/>
              <path className="st9" d="M64.7,19.3c-0.2-1-0.3-3.4-0.2-4.2c0.1-0.4,0.2-0.9,0.2-1.3c0.2-0.6,0.7-1.9,0.8-1.9c0,0,0.4,0.4,2.2,2.5
                l0.2,0.2l-0.5,0.6c-1.2,1.4-2.1,3-2.6,4.5C64.8,20,64.8,19.9,64.7,19.3z"/>
              <path className="st9" d="M29.1,20.4c-1.6-0.8-3-1.8-3.8-2.7l-0.4-0.4l0.3-0.3c1.8-1.6,2.3-2,2.3-1.9c0,0.1,0.1,0.5,0.3,0.9
                c0.5,1.6,1.2,3.2,1.9,4.1c0.5,0.7,0.5,0.7,0.5,0.7C30.1,20.9,29.7,20.7,29.1,20.4z"/>
              <path className="st9" d="M40.6,18.8c-2.3-0.6-4.7-1.8-6.5-3.1c-0.9-0.7-2.2-1.9-2.8-2.7l-0.5-0.6l0.2-0.2c0.1-0.1,0.3-0.2,0.3-0.2
                c0,0,0.3-0.2,0.6-0.4c0.7-0.5,0.6-0.5,2.7-1.8c0.3-0.2,2.5-1.4,2.6-1.4c0,0,0.1,0.3,0.2,0.8c0.4,3,1.8,6.7,3.5,9.3
                c0.2,0.3,0.4,0.6,0.4,0.7C41.2,18.9,40.9,18.8,40.6,18.8z"/>
              <path className="st9" d="M49.7,17.3c-0.3-0.2-1-0.8-1.5-1.3c-2.7-2.6-4.2-6-4.3-9.8l0-0.7l0.6-0.1c0.4-0.1,0.8-0.2,0.9-0.2
                c0.7-0.2,4.6-0.5,4.7-0.4c0,0,0,0.3-0.2,0.7c-0.9,3-1.3,6.1-1,8.4c0.2,1.2,0.5,2.5,0.9,3.3c0.1,0.3,0.2,0.5,0.2,0.5
                C50.3,17.6,50,17.4,49.7,17.3z"/>
              <path className="st9" d="M57.4,16.8c-0.4-0.7-0.7-1.6-1-2.7c-0.3-1.1-0.6-3.5-0.5-4.7C56,8,56.5,6,56.7,6c0.1,0,1.8,0.7,2.3,1
                c0.2,0.1,0.4,0.2,0.5,0.3c0.1,0,0.5,0.3,1.6,0.9c0.2,0.1,0.4,0.3,0.4,0.3c0,0-0.2,0.4-0.6,0.7c-1.2,1.4-2.1,2.8-2.7,4.2
                c-0.5,1.1-0.6,1.8-0.6,2.9l0,0.9L57.4,16.8z"/>
            </g>
            <g>
              <path className="st10" d="M98.3,30.9c4.7,2.7,8.3,4.8,10.8,6.3c19.8,11.3,57.2,30.8,57.8,29.7c0.9-1.7,1.2-3.1,0.8-4.1
                c0.3,0.9-3.8-0.2-12.2-3.3c-8.1-2.9-27-12.9-56.7-29.8L98.3,30.9z"/>
              <path className="st11" d="M153.4,61.1c-19.7-10-54.3-29.2-55.3-29.7c-0.2-0.1,0-0.5,0.4-1.2c0,1.7,35.9,20.8,55.7,30.5
                c4.3,2.1,13.2,5.1,13.4,4.1c0.1-0.3-0.4,1.4-0.8,2C166.7,67,165.1,67,153.4,61.1z"/>
              <path className="st10" d="M152,54.4l-0.3,1c2,0.9,4.5,1.7,7.4,2.4c2.9,0.7,5.9,1.3,9,1.6c0.4-0.4,0.5-0.9,0.4-1.6
                c0-0.1-5.1-0.5-10.6-2c-2.9-0.8-4.7-1.8-5.3-2.8L152,54.4z"/>
              <path className="st11" d="M151.8,55.8c0.1,0.6,11.6,4,13.5,4.5c1,0.2,1.9,0.4,2.9,0.4l0.4-2c-1.4,0.8-7.3-0.4-10.3-1.2
                c-2.5-0.7-6.3-2.2-6.3-3.1C151.8,54.9,151.8,55.4,151.8,55.8z"/>
              <path className="st10" d="M153.3,49.9l-0.3,1c2,1,4.4,1.8,7.3,2.4c11.3,2.4,15.8,1.7,13.4-2c-0.4,0.9-1,1.4-1.6,1.6
                c-1.6,0.5-2.3,0.6-3.3,0.6c-4.1,0.1-5.8-0.6-10.2-1.8c-2.5-0.7-4.1-1.8-4.9-3.3L153.3,49.9z"/>
              <path className="st11" d="M153,51.3c0.1,0.6,11.6,4,14.6,4.1c6,0.1,8.1-1.3,6.1-4.1c0.7,1.1,0.1,1.9-1.6,2.4
                c-4.5,1.4-9.7,0-12.6-0.8c-2.5-0.7-6.2-2.2-6.3-3.1C153.1,50.4,153,50.9,153,51.3z"/>
              <g>
                <path className="st12" d="M172.9,51.8c-0.6-0.1-0.9-0.1-1.1-0.1c-1-0.1-0.2-1.9,0.4-0.9C172.6,51.3,172.8,51.7,172.9,51.8z"/>
                <path className="st12" d="M173.3,50.9c-0.6-0.4-1-0.7-1.1-0.8c-0.9-1.2,1.6-1.1,1.3-0.4C173.3,50.1,173.2,50.5,173.3,50.9z"/>
                <path className="st12" d="M174.2,51.1c-0.1-0.1-0.2-0.3-0.4-0.4c-0.8-0.7,0.7-0.8,0.7-0.3C174.4,50.8,174.3,51.1,174.2,51.1z"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
  </svg>
);

export default Logo;
